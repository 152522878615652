import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";

import './Assets/google_material_icons.woff2';
import './Assets/google_material_icons.css';
import './Assets/sb1-fonts.css';
import styles from './App.module.scss';

import languages from "./Assets/Language/Langloader";
import { ProvideAuth, useAuth } from "./Auth";
import LocaleHandler from "./LocaleHandler";
import Login from './components/Pages/Login/Login';
import { I18n } from 'react-polyglot';
import Loanapplication from './components/Pages/Loanapplication/Loanapplication';
import LoanOverview from './components/Pages/LoanOverview/LoanOverview';
import Header from './components/Shared/Header/Header';
import Insuranceapplication from './components/Pages/Insuranceapplication/Insuranceapplication';
import ErrorBoundary from './ErrorBoundary';
import FullpageHelp from './components/Shared/FullpageHelp/FullpageHelp';
import PopUp from './components/Shared/PopUp/PopUp';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Insuranceoverview from './components/Pages/InsuranceOverview/InsuranceOverview';
import Messages from './components/Shared/Messages/Messages';
import Statistics from './components/Pages/Statistics/Statistics';
import Giosg from './components/Shared/GiosgChat/Giosg';
import Settings from './components/Pages/Settings/Settings';
import { ProvideAxios } from './AxiosHandler';
import { ProvideLoanapplication } from './LoanapplicationContext';
import { config } from './functions/config';

function PrivateRoute({ children }: React.PropsWithChildren<{}>): JSX.Element {
    const auth = useAuth();
    return <div>{
        auth.user != undefined && auth.user.jwt != "" ?
            children
        :
            <Navigate
                to={ "/login" + window.location.search }
                state={{ from: window.location.pathname }}
            />
    }</div>;  
    // return <div>{children}</div>;
}

function App() {
    let localeTemp = "no";
    if (localStorage.getItem("user") != null) {
        let localUser = localStorage.getItem("user");
        if (localUser != null) {
            localUser = JSON.parse(localUser);
            if (localUser != null) {
                if ((localUser as any)["userdata"]["language"] != undefined) {
                    localeTemp = (localUser as any)["userdata"]["language"].toLowerCase();
                }
            }
        }
    }
    let languageTemp: any = {};
    languageTemp = languages(localeTemp);

    const [locale, setLocale] = React.useState(localeTemp);
    const [messages, setMessages] = React.useState(languageTemp);

    const [theme, setTheme] = React.useState(localStorage.getItem("theme") || "light");

    const [menuCollapsed, setMenuCollapsed] = React.useState(false);
    
    useEffect(
        () => {
            if (localStorage.getItem("user") != null) {
                let localUser = localStorage.getItem("user");
                if (localUser != null) {
                    localUser = JSON.parse(localUser);
                    if (localUser != null) {
                        if ((localUser as any)["userdata"]["language"] != undefined) {
                            setLocale((localUser as any)["userdata"]["language"].toLowerCase());
                        }
                    }
                }
            }
            setMessages(languages(locale));
        }, [locale]
    )

    useEffect(() => {
        function checkTheme() {
            const item = localStorage.getItem('theme');
            if (item) {
                setTheme(item)
            }
        }
        
        window.onstorage = checkTheme;
        
        return () => {
            window.removeEventListener('storage', checkTheme)
        }
    }, []);

    return (
        <ErrorBoundary>
            <LocaleHandler.Provider value={{locale, setLocale}}>
                <div className={styles.app + " " + (menuCollapsed ? styles.menucollapsed : "") + " " + (theme)} role="app">
                    <ProvideAuth>
                        <ProvideLoanapplication>
                            <ProvideAxios>
                                <I18n locale={locale} messages={messages}>
                                    <>
                                        <ToastContainer
                                            position="bottom-right"
                                            autoClose={6000}
                                            icon={({theme, type}) => {
                                                if (type === toast.TYPE.DEFAULT) return <span className="material-symbols-outlined">pending</span>;
                                                if (type === toast.TYPE.INFO) return <span className="material-symbols-outlined">lightbulb_circle</span>;
                                                if (type === toast.TYPE.SUCCESS) return <span className="material-symbols-outlined">check_circle</span>;
                                                if (type === toast.TYPE.ERROR) return <span className="material-symbols-outlined">error</span>;
                                            }}
                                            className={styles.toast}
                                        />
                                        <FullpageHelp></FullpageHelp>
                                        <Messages/>
                                        <Giosg
                                            giosgId={config.giosg || ""}
                                            theme={theme}
                                        />
                                        <Router>
                                            <>
                                                <Header onCollapse={(state) => {
                                                    setMenuCollapsed(state);
                                                }}/>
                                                <div className={styles.maincontent}>
                                                    <PopUp></PopUp>
                                                    <Routes>
                                                        <Route path="/" element={
                                                            <Navigate
                                                                replace
                                                                to={ "/loanapplication" + window.location.search }
                                                            />
                                                        } />
                                                        <Route path="/login" element={ <Login /> }/>
                                                        <Route path="/loanapplication" element={<PrivateRoute><Loanapplication /></PrivateRoute>} />
                                                        {/* For forcing refresh when navigating to current path */}
                                                        <Route path="/loanoverviewRefresh" element={
                                                            <Navigate
                                                                replace
                                                                to={ "/loanoverview" }
                                                            />
                                                        } />
                                                        <Route path="/loanoverview" element={<PrivateRoute><LoanOverview /></PrivateRoute>}/>
                                                        <Route path="/insuranceapplication" element={<PrivateRoute><Insuranceapplication /></PrivateRoute>} />
                                                        <Route path="/insuranceoverviewRefresh" element={
                                                            <Navigate
                                                                replace
                                                                to={ "/insuranceoverview" }
                                                            />
                                                        } />
                                                        <Route path="/insuranceoverview" element={<PrivateRoute><Insuranceoverview /></PrivateRoute>} />
                                                        <Route path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>} />
                                                        <Route path="/statisticsRefresh" element={
                                                            <Navigate
                                                                replace
                                                                to={ "/statistics" }
                                                            />
                                                        } />
                                                        <Route path="/statistics" element={<PrivateRoute><Statistics /></PrivateRoute>} />
                                                    </Routes>
                                                </div>
                                            </>
                                        </Router>
                                    </>
                                </I18n>
                            </ProvideAxios>
                        </ProvideLoanapplication>
                    </ProvideAuth>
                </div>
            </LocaleHandler.Provider>
        </ErrorBoundary>
    );
}

export default App;