import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../../../Auth";
import { useAxios } from '../../../AxiosHandler';

import styles from './Loanapplication.module.scss';
import CustomerSegmentSelector from "../../Shared/CustomerSegmentSelector/CustomerSegmentSelector";
import ContentCard from "../../Shared/ContentCard/ContentCard";
import { Calculator, CalculatorRef } from "../../Shared/Calculator/Calculator";
import CategorySelector from "../../Shared/CategorySelector/CategorySelector";
import { InputGroup, TextArea, RadioButtonInputGroup, RadioSwitch } from "@sb1/ffe-form-react";
import { Grid, GridCol, GridRow } from "@sb1/ffe-grid-react";
import Spinner from "@sb1/ffe-spinner-react";
import { ActionButton, SecondaryButton, TertiaryButton } from "@sb1/ffe-buttons-react";
import SelectorList from "../../Shared/SelectorList/SelectorList";
import { Loanapplication as LoanapplicationModel} from "../../../model/Loanapplication";
import FullscreenLoading from "../../Shared/FullscreenLoading/FullscreenLoading";
import { toast } from 'react-toastify';
import moment from "moment";

import CalculationPrint from "./Partials/CalculationPrint/CalculationPrint";
import CaseInsuranceCard from "./Partials/CaseInsuranceCard/CaseInsuranceCard";
import CaseCustomerCard from "./Partials/CaseCustomerCard/CaseCustomerCard";
import customerFunctions from "../../../functions/customerFunctions";
import general from "../../../functions/general";
import CaseObjectCard from "./Partials/CaseObjectCard/CaseObjectCard";
import urlParamsLoader from "../../../functions/urlParamsLoader";
import { useLoanapplication } from "../../../LoanapplicationContext";
import TabSelector from "../../Shared/TabSelector/TabSelector";
import { formatPhoneNumber, formatRegistrationNumber } from "../../../functions/dataSanitation";
import LoanInsuranceCard from "./Partials/LoanInsuranceCard/LoanInsuranceCard";
import ObjectSupplierCard from "./Partials/ObjectSupplierCard/ObjectSupplierCard";
import { config } from "../../../functions/config";
import { CustomerVariable } from "../../../model/CustomerVariable";

function Loanapplication() {
    const auth = useAuth();
    const loanapplication = useLoanapplication();
    const axiosHandler = useAxios();
    const axios = axiosHandler.axios;
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const calculatorRef = React.useRef<CalculatorRef>(null);

    const sendToEsignString = "Kan sendes rett til e-signering etter behandling.";
    const sendToEsignRegex = /\s*Kan sendes rett til e-signering etter behandling./;

    const [searchParamCategory, setSearchParamCategory] = useState<string | null>(searchParams.get("sb1-produkt"));
    const [searchParamType, setSearchParamType] = useState<string | null>(searchParams.get("sb1-typelaan"));
    const [searchParamSegment, setSearchParamSegment] = useState<string | null>(searchParams.get("sb1-kundetype"));
    const [searchParamCaseObjectNew, setSearchParamCaseObjectNew] = useState<string | null>(searchParams.get("sb1-tilstand"));
    const [searchParamPurchaseSum, setSearchParamPurchaseSum] = useState<string | null>(searchParams.get("sb1-pris"));
    const [searchParamDownPaymentSum, setSearchParamDownPaymentSum] = useState<string | null>(searchParams.get("sb1-egenkapital"));
    const [searchParamContractlengthmonths, setSearchParamContractlengthmonths] = useState<string | null>((Math.round((Number(searchParams.get("sb1-nedbetalingstid")) / 12) * 10) / 10).toString());
    const [searchParamDueday, setSearchParamDueday] = useState<string | null>(searchParams.get("sb1-forfallsdag"));
    const [searchParamRegnumber, setSearchParamRegnumber] = useState<string | null>(searchParams.get("sb1-regnr"));
    const [searchParamObjectyear, setSearchParamObjectyear] = useState<string | null>(searchParams.get("sb1-aarsmodell"));
    const [searchParamObjectmake, setSearchParamObjectmake] = useState<string | null>(searchParams.get("sb1-merke"));
    const [searchParamObjectmodel, setSearchParamObjectmodel] = useState<string | null>(searchParams.get("sb1-modell"));
    const [searchParamComment, setSearchParamComment] = useState<string | null>(searchParams.get("sb1-kommentar"));
    const [searchParamCustomerEmail, setSearchParamCustomerEmail] = useState<string | null>(searchParams.get("sb1-epost"));
    const [searchParamCustomerPhone, setSearchParamCustomerPhone] = useState<string | null>(searchParams.get("sb1-mobil"));
    
    const [uniqueCategories, setUniqueCategories] = useState<any>([]);
    // const [uniqueVariants, setUniqueVariants] = useState<any>([]);
    const [uniqueSegments, setUniqueSegments] = useState<any>([]);
    const [uniqueTypes, setUniqueTypes] = useState<any>([]);
    const [uniqueStates, setUniqueStates] = useState<any>([]);

    const [calculatorChangesMade, setCalculatorChangesMade] = useState(false);

    const [openCards, setOpenCards] = useState<any>(0);

    const [nominalInterestRateIsDefault, setNominalInterestRateIsDefault] = useState<boolean>(loanapplication?.nominalInterestRate == undefined ? true : false);

    const [exactAgreement, setExactAgreement] = useState<boolean>(false);

    const [fullscreenLoading, setFullscreenLoading] = useState<boolean>(false);

    const [problems, setProblems] = useState<Array<string>>([]);

    const [foundVehicle, setFoundVehicle] = useState<string>("");

    const [triggerPrint, setTriggerPrint] = useState<number>(0);

    const [objectCategorySequence, setObjectCategorySequence] = useState<number | undefined>(undefined);
    const [objectInsuranceFremtindCode , setObjectInsuranceFremtindCode] = useState<string | undefined>(undefined);

    const [sendingLoanApplication, setSendingLoanApplication] = useState<boolean>(false);

    function isReady() {
        return auth.selectedAgreement != undefined && exactAgreement;
    }

    function stringCategoryToIds(category: string) {
        let categoryName = category?.split("|")[0];
        let variantName = category?.split("|")[1];
        let categoryId = uniqueCategories.find((i: any) => i.value == categoryName + "|" + variantName)?.typeid;
        let variantId = uniqueCategories.find((i: any) => i.value == categoryName + "|" + variantName)?.variantid;
        return categoryId + "|" + variantId;
    }

    function idCategoryToStrings(categoryIds: string) {
        if (categoryIds.length == 73) {
            let categoryId = categoryIds?.split("|")[0];
            let variantId = categoryIds?.split("|")[1];
            let categoryName = uniqueCategories.find((i: any) => i.typeid == categoryId && i.variantid == variantId)?.value.split("|")[0];
            let variantName = uniqueCategories.find((i: any) => i.typeid == categoryId && i.variantid == variantId)?.value.split("|")[1];
            return categoryName + "|" + variantName;
        } else {
            return categoryIds;
        }
    }

    function clearForm() {
        loanapplication.clearForm();
        auth.setSelectedAgreement(undefined);
        setFoundVehicle("");
        setNominalInterestRateIsDefault(true);
        setExactAgreement(false);
        setSearchParams({});
        setOpenCards(openCards + 1);
        window.scrollTo({ top: 0, behavior: "smooth" });
    }

    function getCustomerSector(){
        if(loanapplication?.segment == "Bedrift" && loanapplication?.customerSector == "8500") return "8200";
        if(loanapplication?.segment == "Privat" && loanapplication?.customerSector == "8200") return "8500";
        
        return loanapplication?.customerSector
    }

    function getInsuranceProductValue(){
        let type = general.getProduktType(objectCategorySequence, objectInsuranceFremtindCode);
        if(type == "OTHER") type = "";

        return type;
        /* switch(objectCategorySequence){
            case 1:
                return "BIL";
            case 6:
                return "BOBIL";
            case 7:
                return "CAMPINGVOGN";
            default:
                return "";
        } */
    }

    function filterAgreement () {
        let agreements = auth.selectedDealer?.DealerGroupAgreementConnection || [];

        let uniqueSegments: any[] = [];
        agreements.forEach((i:any) => {
            if (!uniqueSegments.map(o => o.value).includes(i.customertype["name"])) {
                uniqueSegments.push({
                    name: i.customertype["name"],
                    value: i.customertype["name"]
                });
            }
        });
        setUniqueSegments(uniqueSegments);
        agreements = agreements.filter((agreement: any) => loanapplication?.segment == undefined || agreement.customertype.name == loanapplication?.segment);


        let uniqueTypes: any[] = [];
        agreements.forEach((i:any) => {
            if (!uniqueTypes.map(o => o.value).includes(i.financingtype["name"])) {
                uniqueTypes.push({
                    name: i.financingtype["name"],
                    value: i.financingtype["name"]
                });
            }
        });
        setUniqueTypes(uniqueTypes.sort((a, b) => b?.value?.localeCompare(a?.value)));
        agreements = agreements.filter((agreement: any) => loanapplication?.type == undefined || agreement.financingtype.name == loanapplication?.type);


        let uniqueCategories: any[] = [];
        agreements.forEach((i:any) => {
            if (!uniqueCategories.map(o => o.value).includes(i.objecttype["name"] + "|" + i.objectvariant["name"])) {
                uniqueCategories.push({
                    name: (i.objecttype["displayName"] || i.objecttype["name"]) + (i.objectvariant["name"] != "Vanlig" ? (" (" + i.objectvariant["name"] + ")") : ""),
                    value: i.objecttype["name"] + "|" + i.objectvariant["name"],
                    typeid: i.objecttype["id"],
                    variantid: i.objectvariant["id"],
                    typeSequence: i.objecttype["sequence"],
                    insuranceFremtindCode: i.insurance["fremtindCode"],
                });
            }
        });
        setUniqueCategories(uniqueCategories);
        auth.setUniqueCategories(uniqueCategories);
        agreements = agreements.filter((agreement: any) => loanapplication?.category == undefined || (agreement.objecttype.name + "|" + agreement.objectvariant.name) == loanapplication?.category);


        let uniqueStates: any[] = [];
        agreements.forEach((i:any) => {
            if (!uniqueStates.map(o => o.value).includes(i.objectstate["name"])) {
                uniqueStates.push({
                    name: i.objectstate["name"],
                    value: i.objectstate["name"]
                });
            }
        });
        setUniqueStates(uniqueStates);
        agreements = agreements.filter((agreement: any) => loanapplication?.caseObjectNew == undefined || agreement.objectstate.name == (loanapplication?.caseObjectNew ? "Ny" : "Brukt"));


        let uniqueVariants: any[] = [];
        agreements.forEach((i:any) => {
            if (!uniqueVariants.map(o => o.id).includes(i.agreementvariant["id"])) {
                uniqueVariants.push({
                    name: i.agreementvariant["name"],
                    value: i.agreementvariant["name"],
                    id: i.agreementvariant["id"]
                });
            }
        });
        loanapplication?.setUniqueVariants(uniqueVariants);
        agreements = agreements.filter((agreement: any) => loanapplication?.variant == undefined || agreement.agreementvariant.id == loanapplication?.variant);


        let agreementResult = general.getAgreement(auth.selectedDealer, loanapplication);
        let agreement = agreementResult.agreement;

        if (agreementResult.exactAgreement && !exactAgreement) {
            setExactAgreement(true);
        } else if (!agreementResult.exactAgreement && exactAgreement) {
            setExactAgreement(false);
        }

        if (agreements[0] != undefined) {
            // Setting agreement connection ID
            loanapplication.setDealerGroupAgreementConnectionID(agreements[0].DealerGroupAgreementConnectionID as string);
            loanapplication.setDealerGroupAgreementConnection(agreements[0]);
        }

        if (!fullscreenLoading && agreement != undefined && (auth.selectedAgreement == undefined || auth.selectedAgreement?.Sequence != agreement.agreement.id)) {
            fetchAgreement(agreement.agreement.id).then((data: any) => {
                auth.setSelectedAgreement(data);
                
                if (loanapplication.contractlengthmonths == undefined) {
                    loanapplication.setContractlengthmonths(data.ContractDurationDefault);
                }
                if (
                    loanapplication.dueday == undefined ||
                    general.showArea("tvingForfallForste", data, auth.selectedDealer, loanapplication)
                ) {
                    loanapplication.setDueday(data.DueDayDefault);
                    setSearchParamDueday(data.DueDayDefault);
                    changeSingleSearchParam("sb1-forfallsdag", data.DueDayDefault);
                }
                if (loanapplication.downPaymentPercentage == undefined) {
                    loanapplication.setDownPaymentPercentage(data.DownPaymentPctDefault);
                }
                if (loanapplication.startRentInclMvaPct == undefined) {
                    loanapplication.setStartRentInclMvaPct(data.DownPaymentPctDefault);
                }
                if (loanapplication.startRent == undefined) {
                    loanapplication.setStartRentPct(data.DefaultOverrideInitialPayment !== null ? data.DefaultOverrideInitialPayment : data.DownPaymentPctDefault);
                }
                if (loanapplication.startRentExclMvaPct == undefined) {
                    loanapplication.setStartRentExclMvaPct(data.DownPaymentPctDefault);
                }
                if (nominalInterestRateIsDefault) {
                    loanapplication.setNominalInterestRate((data.InterestMarginDefault + data.BaseInterest));
                }

                if (uniqueCategories.length == 1) loanapplication.setCategory(uniqueCategories[0].value);
                if (uniqueVariants.length == 1) loanapplication.setVariant(uniqueVariants[0].id);
                if (uniqueSegments.length == 1) loanapplication.setSegment(uniqueSegments[0].value);
                if (uniqueTypes.length == 1) loanapplication.setType(uniqueTypes[0].value);
                if (uniqueStates.length == 1) loanapplication.setCaseObjectNew(uniqueStates[0].value);

                // Blanking calculation result to avoid showing old result
                loanapplication.setCalculationResult(undefined);
                
                setTimeout(() => calculatorRef.current?.recalculate(), 1);
            }).catch((err: any) => {
                console.log(err);
            });
        }

        if ((uniqueVariants.find((i: any) => i.id == loanapplication?.variant) == undefined && uniqueVariants.find((i: any) => i.value == loanapplication?.variant)) == undefined) {
            if (uniqueVariants.find((i: any) => i.value == "Standard") != undefined) {
                loanapplication.setVariant(uniqueVariants.find((i: any) => i.value == "Standard")?.id);
            } else {
                loanapplication.setVariant(uniqueVariants[uniqueVariants.length]?.id);
            }
        }
    }

    function isPossibleAgreement(segment?: string, type?: string, objectVariant?: string, objectstate?: string) {
        let agreements = auth.selectedDealer?.DealerGroupAgreementConnection || [];

        if(segment)
            agreements = agreements.filter((agreement: any) => agreement.customertype.name == segment);

        if(type)
            agreements = agreements.filter((agreement: any) => agreement.financingtype.name == type);

        if(objectVariant) {
            let objectVariants: any[] = [];
            agreements.forEach((i:any) => {
                if (!objectVariants.includes(i.objecttype["name"] + "|" + i.objectvariant["name"])) {
                    objectVariants.push(i.objecttype["name"] + "|" + i.objectvariant["name"]);
                }
            });

            agreements = agreements.filter((agreement: any) => objectVariants.includes(objectVariant));
        }

        if (objectstate)
            agreements = agreements.filter((agreement: any) => agreement.objectstate.name == objectstate);

        return agreements.length !== 0;
    }

    async function fetchAgreement(agreementId: string) {
        return new Promise((resolve, reject) => {
            toast.dismiss();
            setFullscreenLoading(true);
            if (agreementId !== undefined) {
                axios.single({
                    url: "/api/agreements/" + agreementId,
                    method: "GET",
                    headers: {
                        "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
                    }
                }).then((res: any) => {
                    resolve(res.data);
                    setFullscreenLoading(false);
                }).catch((err: any) => {
                    console.log(err);
                    setFullscreenLoading(false);
                    toast.error("Innlasting av avtaler feilet! Klikk her for å prøve igjen", {
                        position: "bottom-right",
                        autoClose: false,
                        onClick: () => {
                            fetchAgreement(agreementId);
                        }
                    });

                    reject(err);
                });
            }
        });
    }

    async function sendNote(caseSequence: number, noteSequence?: number) {
        return new Promise((resolve, reject) => {
            axios.single({
                url: "/api/casenotes/",
                data: [
                    noteSequence == undefined ? {
                        "FinanceCaseSequence": !caseSequence.toString().includes("-") ? caseSequence : undefined,
                        "FinanceCaseID": caseSequence.toString().includes("-") ? caseSequence : undefined,
                        "Text": loanapplication.comment
                    } : {
                        "FinanceCaseSequence": !caseSequence.toString().includes("-") ? caseSequence : undefined,
                        "FinanceCaseID": caseSequence.toString().includes("-") ? caseSequence : undefined,
                        "Sequence": noteSequence,
                        "Text": loanapplication.comment
                    }
                ],
                method: noteSequence == undefined ? "POST" : "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
                }
            }).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                console.log(err);
                toast.dismiss();
                toast.error("Lagring av notat feilet! Vennligst lagre søknaden på nytt");
                reject(err);
            });
        });
    }

    function getDownPaymentValue() {
        if (general.showArea("egenkapital", auth.selectedAgreement, auth.selectedDealer, loanapplication))
            return loanapplication?.downPaymentSum;

        if (general.showArea("startleie", auth.selectedAgreement, auth.selectedDealer, loanapplication) || general.showArea("startleieInklMva", auth.selectedAgreement, auth.selectedDealer, loanapplication))
            return loanapplication?.calculationResult.DownPayment;

        if (general.showArea("startleieEksMva", auth.selectedAgreement, auth.selectedDealer, loanapplication))
            return loanapplication?.calculationResult.DownPayment;

        return 0;
    }

    function getPreviewFormattedErrorMessage(message: string) {
        switch (message) {
            case "Manglende parameter OrgLastName":
                return "Mangler kundeinformasjon";
            default:
                return message;
        }
    }

    function updateLoanapplication(state?: string) {
        if (loanapplication?.calculationResult == undefined || calculatorChangesMade) {
            toast.error("Kalkulering må kjøres før søknaden kan sendes!");
            return;
        }

        toast(state == "Kladd" ? "Lagrer kladd .." : "Lagrer søknad...", { autoClose: false });
        let deliveryDateFormatted = undefined;
        if (loanapplication?.deliveryDate != null) {
            let deliveryDate = loanapplication?.deliveryDate;
            let momentISOString = general.dateToDateString(deliveryDate) || "";
            deliveryDateFormatted = momentISOString.split("T")[0];
        }
        setSendingLoanApplication(true);
        
        axios.single({
            url: "/api/cases/" + (state == "Kladd" ? loanapplication?.ID : loanapplication?.Sequence),
            data: {
                "ID": (state == "Kladd" ? loanapplication?.ID : undefined),
                "Sequence": (state == "Kladd" ? undefined : loanapplication?.Sequence),
                "AgreementSequence": auth.selectedAgreement?.Sequence,

                "DoScoring": true,
                
                "Calculation": [{
                    ...loanapplication?.calculationResult,
                    AgreementSequence: auth.selectedAgreement?.Sequence,
                    "DownPayment": getDownPaymentValue(),
                }],
                "CaseObjects": [{
                    "ObjectTypeSequence": loanapplication?.dealerGroupAgreementConnection?.objecttype.sequence,
                    "IsNew" : loanapplication?.caseObjectNew,
                    "MakeName" : loanapplication?.objectmakeName,
                    "ModelName" : loanapplication?.objectmodelName,
                    "TypeName": loanapplication?.objecttypeName,
                    "ModelYear" : Number(loanapplication?.objectyear),
                    "InsurerSequence": loanapplication?.insuranceCompany,
                    "Price" : loanapplication?.purchaseSum,
                    "RegistrationNumber" : formatRegistrationNumber(loanapplication?.regnumber || "", true),
                    "RegistrationDate": loanapplication?.regnumber != "" && loanapplication?.regnumber != undefined ? loanapplication?.registrationDate : undefined,
                    "VATExempt": general.showArea("kjopesumFritattMva", auth.selectedAgreement, auth.selectedDealer, loanapplication) || (general.showArea("ingenMvaVedBrukt", auth.selectedAgreement, auth.selectedDealer, loanapplication) && !loanapplication.caseObjectNew) ? true : undefined,
                    "ChassisNumber": loanapplication?.chassisnumber,
                    "ResidualValueGuarantorSequence": loanapplication?.type == "Lån" || loanapplication?.remainingValue == 0 || loanapplication?.remainingValue == undefined ? undefined : auth.selectedDealer?.Sequence,
                    "Sequence" : loanapplication?.objectSequence,
                    "ID": loanapplication?.objectID,
                    "AgreedMileage": general.showArea("antallKilometer", auth.selectedAgreement, auth.selectedDealer, loanapplication) ? Number(loanapplication?.useableKm) : undefined,
                    "RegistryFee": loanapplication?.onetimeFee,
                    "MileagePrice": general.showArea("prisOverkjortKmEksMva", auth.selectedAgreement, auth.selectedDealer, loanapplication) ? Number(loanapplication?.priceExtraKmExclVAT) : (general.showArea("prisOverkjortKmInklMva", auth.selectedAgreement, auth.selectedDealer, loanapplication) ? (Number(loanapplication?.priceExtraKmInclVAT) * 0.8) : undefined),
                    "Mileage": general.showArea("kmStand", auth.selectedAgreement, auth.selectedDealer, loanapplication) ? Number(loanapplication?.mileage) : undefined,
                    "DeliveryDate": general.showArea("antattLeveringsdato", auth.selectedAgreement, auth.selectedDealer, loanapplication) ? deliveryDateFormatted : undefined, //TODO: This may need different formatting
                    "SupplierSequence": loanapplication?.objectSupplierSequence,
                }],
                "IsCustomerPrivate": loanapplication?.customerSector == "8500" && loanapplication?.segment != "Bedrift" ? true : false,
                "Status": state,
                "DueDay": Number(loanapplication?.dueday),
                "FinanceCaseExtension": [
                    {
                        "DealerGroupAgreementConnectionID": loanapplication?.dealerGroupAgreementConnectionID,
                        "CaseObjectMakeID": loanapplication.objectmake,
                        "CaseObjectModelID": loanapplication.objectmodel,
                        "CaseObjectVariantID": loanapplication.objecttype,
                        "InsuranceIsRequested": loanapplication?.requestingInsurance,
                        "InsuranceCompanyRequested": loanapplication?.insuranceCompany,
                        "InsuranceExistingBonus": loanapplication?.insuranceBonus,
                        "InsuranceForPersonsUnder23": loanapplication?.insuranceLowAge,
                        "InsuranceRequestedMileage": loanapplication?.insuranceAnnualKilometers,
                        "InsuranceCoverageTypeCode": loanapplication?.insuranceCoverageTypeCode,
                        "InsuranceDeductibleCode": loanapplication?.insuranceDeductibleCode,
                        "SchemaCustomerType": loanapplication?.segment,
                        "SchemaObjectVariant": stringCategoryToIds(loanapplication?.category), //loanapplication?.category,
                        "SchemaAgreementVariant": loanapplication?.variant,
                        "ChassisNumber": loanapplication?.chassisnumber,
                        "InsuranceProduct": getInsuranceProductValue(),
                        "CaseObjectMakeCode": objectCategorySequence == 7 ? loanapplication?.objectmakeCode : undefined,
                        "ObjectSupplierEmail": loanapplication?.objectSupplierEmail,
                        "ObjectSupplierPhone": loanapplication?.objectSupplierPhone,
                        "ObjectSupplierContact": loanapplication?.objectSupplierContact,
                        //"ID": ""
                    }
                ],
                "CustomerSequence": loanapplication?.customerSequence,
                "CustomerSector": getCustomerSector(),
                "CustomerMobile": loanapplication?.customerPhone,
                "CustomerEmail": loanapplication?.customerEmail,
                "CustomerName":
                    loanapplication?.customerSector == "8500" ||
                    loanapplication?.customerSector == "8200" ?
                        loanapplication?.customerLastName + ", " + loanapplication?.customerFirstName
                    :
                        loanapplication?.customerLastName,
                
                "CoBuyerSequence": loanapplication?.coCustomerSequence,
                "CoBuyerSector": loanapplication?.coCustomerSector,
                "CoBuyerMobile": loanapplication?.coCustomerPhone,
                "CoBuyerEmail": loanapplication?.coCustomerEmail,
                "CoBuyerName":
                    loanapplication?.coCustomerSector == "8500" ||
                    loanapplication?.coCustomerSector == "8200" ?
                        loanapplication?.coCustomerLastName + ", " + loanapplication?.coCustomerFirstName
                    :
                        loanapplication?.coCustomerLastName,
                
                "DealerSequence": auth.selectedDealer?.Sequence,
                "InvoiceAddressSequence": Number(loanapplication?.invoiceAddress?.toString()),
                "IsIndicative": !loanapplication?.exactCar,
                "SalesPersonSequence": auth.user?.userdata?.Sequence,
                "UserSequence": auth.user?.userdata?.Sequence,
                "Type": general.enforceValidCaseType(loanapplication?.type),
                "PriceExVAT": loanapplication?.purchaseSumExclMva,
                "CaseVariableCode": general.showArea("midlenesOpprinnelse", auth.selectedAgreement, auth.selectedDealer, loanapplication) || general.showArea("startleieMidlenesOpprinnelse", auth.selectedAgreement, auth.selectedDealer, loanapplication) ? general.getMidlenesOpprinnelseCode() : undefined,
                "CaseVariableValue": general.showArea("midlenesOpprinnelse", auth.selectedAgreement, auth.selectedDealer, loanapplication) || general.showArea("startleieMidlenesOpprinnelse", auth.selectedAgreement, auth.selectedDealer, loanapplication) ? loanapplication?.downPaymentSource : undefined,
                "DebtInsuranceRequested": loanapplication?.debtInsuranceRequested,
                "CaseVariables": general.showArea("flyttKundeinfo", auth.selectedAgreement, auth.selectedDealer, loanapplication) ? loanapplication?.customerVariables : loanapplication?.customerVariables.filter((i: CustomerVariable) => ![20, 21, 22, 80, 81, 28, 29, 82, 86].includes(i.TypeSequence ?? 0))
            },
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
            }
        }).then((res: any) => {
            setSendingLoanApplication(false);

            if(general.isError(res).isError === true){
                toast.dismiss();
                toast.error(state == "Kladd" ? ("Lagring av kladd feilet. Vennligst prøv igjen. " + general.isError(res).errorMessage) : ("Sending av søknad feilet. " + general.isError(res).errorMessage));
                return;
            }
            
            /* if (res.data == undefined) { 
                toast.dismiss();
                toast.error(state == "Kladd" ? "Lagring av kladd feilet. Vennligst prøv igjen." : "Lagring av søknad feilet. Vennligst prøv igjen."); 
                return; 
            }
            if (res.data.error != undefined) { 
                toast.dismiss();
                if (res.data.error.errorMessage != undefined && res.data.error.errorMessage != "")
                    toast.error(state == "Kladd" ? ("Lagring av kladd feilet. Vennligst prøv igjen. Error: " + res.data.error.errorMessage) : ("Lagring av søknad feilet. Error: " + res.data.error.errorMessage));
                else
                    toast.error(state == "Kladd" ? "Lagring av kladd feilet. Vennligst prøv igjen." : "Lagring av søknad feilet. Vennligst prøv igjen.");
                return;
            }

            if(res.status === 500 && typeof res.data === "string"){
                toast.error(state == "Kladd" ? ("Lagring av kladd feilet. Vennligst prøv igjen. " + res.data) : ("Sending av søknad feilet. " + res.data));
                return;
            } */

            let postActions = [];
            postActions.push(sendNote(state == "Kladd" ? loanapplication?.ID : loanapplication?.Sequence, loanapplication?.CaseNotes?.[0]?.Sequence));
            
            Promise.all(postActions).then((res2) => {
                toast.dismiss();
                toast.success(state == "Kladd" ? "Kladd lagret!" : "Søknad lagret!");
            }).catch((err: any) => {
                toast.dismiss();
                console.log(err);
            });
        }).catch((err: any) => {
            setSendingLoanApplication(false);
            console.log(err);
            toast.dismiss();
            if (err.response?.data?.message != undefined) {
                toast.error(state == "Kladd" ? ("Lagring av kladd feilet! " + getPreviewFormattedErrorMessage(err.response?.request?.response)) : ("Lagring av søknad feilet! " + getPreviewFormattedErrorMessage(err.response?.request?.response)));
            } else {
                toast.error(state == "Kladd" ? "Lagring av kladd feilet! Vennligst prøv igjen" : "Lagring av søknad feilet! Vennligst prøv igjen");
            }
        });
    }

    function sendLoanapplication(state?: string) {
        if (loanapplication?.calculationResult == undefined || calculatorChangesMade) {
            toast.error("Kalkulering må kjøres før søknaden kan sendes!");
            return;
        }

        toast(state == "Kladd" ? "Lagrer kladd .." : "Sender søknad ..", { autoClose: false });
        let deliveryDateFormatted = undefined;
        if (loanapplication?.deliveryDate != null) {
            let deliveryDate = loanapplication?.deliveryDate;
            let momentISOString = general.dateToDateString(deliveryDate) || "";
            deliveryDateFormatted = momentISOString.split("T")[0];
        }
        
        setSendingLoanApplication(true);

        let sendSMS = true;
        // If not private customer sector or segment is Bedrift, do not send SMS
        if (loanapplication?.customerSector != "8500" || loanapplication?.segment == "Bedrift") sendSMS = false;
        // If flyttKundeinfo is shown, do not send SMS
        if (general.showArea("flyttKundeinfo", auth.selectedAgreement, auth.selectedDealer, loanapplication)) sendSMS = false;

        axios.single({
            url: "/api/cases",
            data: {
                "ID": loanapplication?.ID,
                "AgreementSequence": auth.selectedAgreement?.Sequence,

                "DoScoring": true,
                "SendSMS": sendSMS, // Calculated above based on customer sector and segment, and flyttKundeinfo

                "Calculation": [{
                    ...loanapplication?.calculationResult,
                    AgreementSequence: auth.selectedAgreement?.Sequence,
                    "DownPayment": getDownPaymentValue(),
                }],
                "CaseObjects": [{
                    "ObjectTypeSequence": loanapplication?.dealerGroupAgreementConnection?.objecttype.sequence,
                    "IsNew" : loanapplication?.caseObjectNew,
                    "MakeName" : loanapplication?.objectmakeName,
                    "ModelName" : loanapplication?.objectmodelName,
                    "TypeName": loanapplication?.objecttypeName,
                    "ModelYear" : Number(loanapplication?.objectyear),
                    "InsurerSequence": loanapplication?.insuranceCompany,
                    "Price" : loanapplication?.purchaseSum,
                    "RegistrationNumber" : formatRegistrationNumber(loanapplication?.regnumber || "", true),
                    "RegistrationDate": loanapplication?.regnumber != "" && loanapplication?.regnumber != undefined ? loanapplication?.registrationDate : undefined,
                    "VATExempt": general.showArea("kjopesumFritattMva", auth.selectedAgreement, auth.selectedDealer, loanapplication) || (general.showArea("ingenMvaVedBrukt", auth.selectedAgreement, auth.selectedDealer, loanapplication) && !loanapplication.caseObjectNew) ? true : undefined,
                    "ChassisNumber": loanapplication?.chassisnumber,
                    "ResidualValueGuarantorSequence": loanapplication?.type == "Lån" || loanapplication?.remainingValue == 0 || loanapplication?.remainingValue == undefined ? undefined : auth.selectedDealer?.Sequence,
                    "RegistryFee": loanapplication?.onetimeFee,
                    "AgreedMileage": general.showArea("antallKilometer", auth.selectedAgreement, auth.selectedDealer, loanapplication) ? Number(loanapplication?.useableKm) : undefined,
                    "MileagePrice": general.showArea("prisOverkjortKmEksMva", auth.selectedAgreement, auth.selectedDealer, loanapplication) ? Number(loanapplication?.priceExtraKmExclVAT) : (general.showArea("prisOverkjortKmInklMva", auth.selectedAgreement, auth.selectedDealer, loanapplication) ? (Number(loanapplication?.priceExtraKmInclVAT) * 0.8) : undefined),
                    "Mileage": general.showArea("kmStand", auth.selectedAgreement, auth.selectedDealer, loanapplication) ? Number(loanapplication?.mileage) : undefined,
                    "DeliveryDate": general.showArea("antattLeveringsdato", auth.selectedAgreement, auth.selectedDealer, loanapplication) ? deliveryDateFormatted : undefined, //TODO: This may need different formatting
                    "SupplierSequence": loanapplication?.objectSupplierSequence,
                }],
                "IsCustomerPrivate": loanapplication?.customerSector == "8500" && loanapplication?.segment != "Bedrift" ? true : false,
                "Status": state,
                "DueDay": Number(loanapplication?.dueday),
                "FinanceCaseExtension": [
                    {
                        "DealerGroupAgreementConnectionID": loanapplication?.dealerGroupAgreementConnectionID,
                        "CaseObjectMakeID": loanapplication.objectmake,
                        "CaseObjectModelID": loanapplication.objectmodel,
                        "CaseObjectVariantID": loanapplication.objecttype,
                        "InsuranceIsRequested": loanapplication?.requestingInsurance,
                        "InsuranceCompanyRequested": loanapplication?.insuranceCompany,
                        "InsuranceExistingBonus": loanapplication?.insuranceBonus,
                        "InsuranceForPersonsUnder23": loanapplication?.insuranceLowAge,
                        "InsuranceRequestedMileage": loanapplication?.insuranceAnnualKilometers,
                        "InsuranceCoverageTypeCode": loanapplication?.insuranceCoverageTypeCode,
                        "InsuranceDeductibleCode": loanapplication?.insuranceDeductibleCode,
                        "SchemaCustomerType": loanapplication?.segment,
                        "SchemaObjectVariant": stringCategoryToIds(loanapplication?.category), //loanapplication?.category,
                        "SchemaAgreementVariant": loanapplication?.variant,
                        "ChassisNumber": loanapplication?.chassisnumber,
                        "InsuranceProduct": getInsuranceProductValue(),
                        "CaseObjectMakeCode": objectCategorySequence == 7 ? loanapplication?.objectmakeCode : undefined,
                        "ObjectSupplierEmail": loanapplication?.objectSupplierEmail,
                        "ObjectSupplierPhone": loanapplication?.objectSupplierPhone,
                        "ObjectSupplierContact": loanapplication?.objectSupplierContact,
                        //"ID": ""
                    }
                ],
                "CustomerSequence": loanapplication?.customerSequence,
                "CustomerSector": getCustomerSector(),
                "CustomerMobile": formatPhoneNumber(loanapplication?.customerPhone),
                "CustomerEmail": loanapplication?.customerEmail,
                "CustomerName":
                    loanapplication?.customerSector == "8500" ||
                    loanapplication?.customerSector == "8200" ?
                        loanapplication?.customerLastName + ", " + loanapplication?.customerFirstName
                    :
                        loanapplication?.customerLastName,
                
                "CoBuyerSequence": loanapplication?.coCustomerSequence,
                "CoBuyerSector": loanapplication?.coCustomerSector,
                "CoBuyerMobile": formatPhoneNumber(loanapplication?.coCustomerPhone),
                "CoBuyerEmail": loanapplication?.coCustomerEmail,
                "CoBuyerName":
                    loanapplication?.coCustomerSector == "8500" ||
                    loanapplication?.coCustomerSector == "8200" ?
                        loanapplication?.coCustomerLastName + ", " + loanapplication?.coCustomerFirstName
                    :
                        loanapplication?.coCustomerLastName,
                
                "DealerSequence": auth.selectedDealer?.Sequence,
                "DoCalculateCommissionSubsidies": true,
                "InvoiceAddressSequence": Number(loanapplication?.invoiceAddress?.toString()),
                "IsIndicative": !loanapplication?.exactCar,
                "IsLockedForFront": false,
                "IsReady": false, // Default false her. Kanskje true fra andre steder? (default kommer fra Agreement senere?)
                "SalesPersonSequence": auth.user?.userdata?.Sequence,
                "UserSequence": auth.user?.userdata?.Sequence,
                "Type": general.enforceValidCaseType(loanapplication?.type),
                "PriceExVAT": loanapplication?.purchaseSumExclMva,
                "CaseVariableCode": general.showArea("midlenesOpprinnelse", auth.selectedAgreement, auth.selectedDealer, loanapplication) || general.showArea("startleieMidlenesOpprinnelse", auth.selectedAgreement, auth.selectedDealer, loanapplication) ? general.getMidlenesOpprinnelseCode() : undefined,
                "CaseVariableValue": general.showArea("midlenesOpprinnelse", auth.selectedAgreement, auth.selectedDealer, loanapplication) || general.showArea("startleieMidlenesOpprinnelse", auth.selectedAgreement, auth.selectedDealer, loanapplication) ? loanapplication?.downPaymentSource : undefined,
                "IncomingSystem": "Partnerportalen",
                "DebtInsuranceRequested": loanapplication?.debtInsuranceRequested,
                "CaseVariables": general.showArea("flyttKundeinfo", auth.selectedAgreement, auth.selectedDealer, loanapplication) ? loanapplication?.customerVariables : loanapplication?.customerVariables?.filter((i: CustomerVariable) => ![20, 21, 22, 80, 81, 28, 29, 82, 86].includes(i.TypeSequence ?? 0)),
            },
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
            }
        }).then((res: any) => {
            setSendingLoanApplication(false);
            toast.dismiss();
            
            if(general.isError(res).isError === true){
                let errorMessage = general.isError(res).errorMessage;
                toast.error(state == "Kladd" ? ("Lagring av kladd feilet. Vennligst prøv igjen. " + errorMessage) : ("Sending av søknad feilet. " + errorMessage));
                return;
            }

            /* if (res.data == undefined) { 
                toast.error(state == "Kladd" ? "Lagring av kladd feilet. Vennligst prøv igjen." : "Sending av søknad feilet. Vennligst prøv igjen."); 
                return; 
            } 
            if (res.data.error != undefined) { 
                if (res.data.error.errorMessage != undefined && res.data.error.errorMessage != "")
                    toast.error(state == "Kladd" ? ("Lagring av kladd feilet. Vennligst prøv igjen. Error: " + res.data.error.errorMessage) : ("Sending av søknad feilet. Error: " + res.data.error.errorMessage));
                else
                    toast.error(state == "Kladd" ? "Lagring av kladd feilet. Vennligst prøv igjen." : "Sending av søknad feilet. Vennligst prøv igjen.");
                return;
            } 

            if(res.status === 500 && typeof res.data === "string"){
                toast.error(state == "Kladd" ? ("Lagring av kladd feilet. Vennligst prøv igjen. " + res.data) : ("Sending av søknad feilet. " + res.data));
                return;
            }*/
            
            loanapplication.setSequence(res.data.sequence);
            loanapplication.setID(res.data.ID);
            
            let postActions = [];
            postActions.push(sendNote(res.data.sequence || res.data.ID));

            // When note and insurance request is saved, navigate to overview
            Promise.all(postActions).then((res2) => {
                navigate("/loanoverview?caseid=" + (res.data.sequence || res.data.ID));
            }).catch((err: any) => {
                console.log(err);
            });

            toast.success(state == "Kladd" ? "Kladd lagret!" : "Søknad sendt!");
        }).catch((err: any) => {
            setSendingLoanApplication(false);
            console.log(err);
            toast.dismiss();
            if (err.response?.request?.response != undefined) {
                toast.error(state == "Kladd" ? ("Lagring av kladd feilet! Melding: " + getPreviewFormattedErrorMessage(err.response?.request?.response)) : ("Lagring av søknad feilet! Melding: " + getPreviewFormattedErrorMessage(err.response?.request?.response)));
            } else {
                toast.error(state == "Kladd" ? "Lagring av kladd feilet! Vennligst prøv igjen" : "Lagring av søknad feilet! Vennligst prøv igjen");
            }
        });
    }

    function sortAddresses(addresses: Array<string>) {
        // Sort addresses by type from sortingmap
        let sortingmap: any = {
            Besøksadresse: 1,
            Offisiell: 2,
            Postadresse: 3,
            Fakturaadresse: 4,
        };

        if (addresses != undefined) {
            addresses.sort((a: any, b: any) => {
                return sortingmap[a.Type] - sortingmap[b.Type];
            });
    
            return addresses;
        } else {
            return [];
        }
    }

    function changeSingleSearchParam (param: string, value: string) {
        let defaultParams: any = {};

        if (searchParamSegment != undefined && searchParamSegment != "") defaultParams["sb1-kundetype"] = searchParamSegment;
        if (searchParamType != undefined && searchParamType != "") defaultParams["sb1-typelaan"] = searchParamType;
        if (searchParamCategory != undefined && searchParamCategory != "") defaultParams["sb1-produkt"] = searchParamCategory;
        if (searchParamCaseObjectNew != undefined && searchParamCaseObjectNew != "") defaultParams["sb1-tilstand"] = searchParamCaseObjectNew;
        if (searchParamPurchaseSum != undefined && searchParamPurchaseSum != "") defaultParams["sb1-pris"] = searchParamPurchaseSum;
        if (searchParamDownPaymentSum != undefined && searchParamDownPaymentSum != "") defaultParams["sb1-egenkapital"] = searchParamDownPaymentSum;
        if (searchParamContractlengthmonths != undefined && searchParamContractlengthmonths != "") defaultParams["sb1-nedbetalingstid"] = (Math.round(Number(searchParamContractlengthmonths) * 10) / 10).toString();
        if (searchParamRegnumber != undefined && searchParamRegnumber != "") defaultParams["sb1-regnr"] = searchParamRegnumber;
        if (searchParamObjectyear != undefined && searchParamObjectyear != "") defaultParams["sb1-aarsmodell"] = searchParamObjectyear;
        if (searchParamObjectmake != undefined && searchParamObjectmake != "") defaultParams["sb1-merke"] = searchParamObjectmake;
        if (searchParamObjectmodel != undefined && searchParamObjectmodel != "") defaultParams["sb1-modell"] = searchParamObjectmodel;
        if (searchParamDueday != undefined && searchParamDueday != "") defaultParams["sb1-forfallsdag"] = searchParamDueday;
        if (searchParamComment != undefined && searchParamComment != "") defaultParams["sb1-kommentar"] = searchParamComment;
        if (searchParamCustomerEmail != undefined && searchParamCustomerEmail != "") defaultParams["sb1-epost"] = searchParamCustomerEmail;
        if (searchParamCustomerPhone != undefined && searchParamCustomerPhone != "") defaultParams["sb1-mobil"] = searchParamCustomerPhone;

        let newParams = {
            ...defaultParams,
        };

        if (value != undefined && value != "") {
            newParams[param] = value;
        } else {
            delete newParams[param];
        }

        setSearchParams(newParams);
    }

    useEffect(() => {
        filterAgreement();

        if (
            loanapplication?.segment == undefined &&
            loanapplication?.type == undefined &&
            loanapplication?.category == undefined &&
            loanapplication?.caseObjectNew == undefined
        ) {
            setOpenCards(openCards + 1);
        }
    }, [
        loanapplication?.segment,
        loanapplication?.type,
        loanapplication?.category,
        loanapplication?.variant,
        loanapplication?.caseObjectNew,
        auth.selectedDealer?.DealerGroupAgreementConnection
    ]);

    useEffect(() => {
        if (loanapplication?.agreementId != undefined) {
            let agreement = auth.selectedDealer?.DealerGroupAgreementConnection?.find((i: any) => i.agreement.id == loanapplication?.agreementId);
            let fetches = [];

            fetches.push(fetchAgreement(loanapplication?.agreementId));

            // If Case has a customer, fetch customer
            if (loanapplication?.customerSocialSecurityNumber != undefined || loanapplication?.customerSequence != undefined) {
                let socialsecuritynum = loanapplication?.customerSocialSecurityNumber;
                // If social security number is 11 digits, fetch person, else fetch company
                if (socialsecuritynum?.length == 11 || loanapplication?.customerSector == "8500" || loanapplication?.customerSector == "8200") {
                    fetches.push(customerFunctions.searchPerson(toast, axios, auth, socialsecuritynum || loanapplication?.customerSequence));
                } else if (socialsecuritynum?.length == 9 || (loanapplication?.customerSector != "8500" && loanapplication?.customerSector != "8200")) {
                    fetches.push(customerFunctions.searchCompany(toast, axios, auth, socialsecuritynum || loanapplication?.customerSequence));
                }
            }

            // If Case has a coCustomer, fetch coCustomer
            if (loanapplication?.coCustomerSocialSecurityNumber != undefined || loanapplication?.coCustomerSequence != undefined) {
                let socialsecuritynum = loanapplication?.coCustomerSocialSecurityNumber;
                // If social security number is 11 digits, fetch person, else fetch company
                if (socialsecuritynum?.length == 11 || loanapplication?.coCustomerSector == "8500" || loanapplication?.coCustomerSector == "8200") {
                    fetches.push(customerFunctions.searchPerson(toast, axios, auth, socialsecuritynum || loanapplication?.coCustomerSequence));
                } else if (socialsecuritynum?.length == 9 || (loanapplication?.coCustomerSector != "8500" && loanapplication?.coCustomerSector != "8200")) {
                    fetches.push(customerFunctions.searchCompany(toast, axios, auth, socialsecuritynum || loanapplication?.coCustomerSequence));
                }
            }

            Promise.all(fetches).then((res: any) => {
                auth.setSelectedAgreement(res[0]);

                // Set default values if not set already
                if (loanapplication.contractlengthmonths == undefined) loanapplication.setContractlengthmonths(res[0].ContractDurationDefault);
                if (loanapplication.dueday == undefined) loanapplication.setDueday(res[0].DueDayDefault);
                if (loanapplication.downPaymentPercentage == undefined) loanapplication.setDownPaymentPercentage(res[0].DownPaymentPctDefault);

                if (res[1] != undefined) { // If we have a customer
                    // Data from company/person search
                    loanapplication.setCustomerSequence(res[1]?.Sequence);
                    loanapplication.setCustomerSector(res[1]?.Sector);
                    loanapplication.setCustomerLastName(res[1]?.Sector == "8500" || res[1]?.Sector == "8200" ? res[1]?.LastName : res[1]?.CompanyName);
                    loanapplication.setCustomerFirstName(res[1]?.Sector == "8500" || res[1]?.Sector == "8200" ? res[1]?.FirstName : undefined);
                    if (!loanapplication.customerEmail) {
                        loanapplication.setCustomerEmail(res[1]?.Email);
                    }
                    if (!loanapplication.customerPhone) {
                        loanapplication.setCustomerPhone(res[1]?.Mobile);
                    }
                    loanapplication.setCustomerAddresses(res[1] != undefined ? sortAddresses(res[1]?.Addresses) as any : []);
                    //loanapplication.customerSocialSecurityNumber = res[1]?.Sector == "8500" || res[1]?.Sector == "8200" ? res[1]?.Personid : res[1]?.OrgNumber;
                    loanapplication.setInvoiceAddress(res[1] != undefined ? sortAddresses(res[1]?.Addresses)[0] as any : []);
                }

                if (res[2] != undefined) { // If we have a coCustomer
                    // Data from company/person coCustomer search
                    loanapplication.setCoCustomerSequence(res[2]?.Sequence);
                    loanapplication.setCoCustomerSector(res[2]?.Sector);
                    loanapplication.setCoCustomerLastName(res[2]?.Sector == "8500" || res[2]?.Sector == "8200" ? res[2]?.LastName : res[2]?.CompanyName);
                    loanapplication.setCoCustomerFirstName(res[2]?.Sector == "8500" || res[2]?.Sector == "8200" ? res[2]?.FirstName : undefined);
                    loanapplication.setCoCustomerEmail(res[2]?.Email);
                    loanapplication.setCoCustomerPhone(res[2]?.Mobile);
                    loanapplication.setCoCustomerAddresses(res[2] != undefined ? sortAddresses(res[2]?.Addresses) as any : []);
                    //loanapplication.coCustomerSocialSecurityNumber = res[2]?.Sector == "8500" || res[2]?.Sector == "8200" ? res[2]?.Personid : res[2]?.OrgNumber;
                    loanapplication.setCoInvoiceAddress(res[2] != undefined ? sortAddresses(res[2]?.Addresses)[0] as any : []);
                }

                // Set filtering values
                loanapplication.setSegment(loanapplication?.SchemaCustomerType || agreement?.customertype?.name);
                loanapplication.setType(agreement?.financingtype?.name);
                if (loanapplication?.SchemaObjectVariant != undefined && loanapplication?.SchemaObjectVariant != null) loanapplication.setCategory(idCategoryToStrings(loanapplication?.SchemaObjectVariant));
                loanapplication.setVariant(loanapplication?.SchemaAgreementVariant || agreement?.agreementvariant["id"]);

                // remove agreementId
                loanapplication.setAgreementId(undefined);

                toast.dismiss();
                setFullscreenLoading(false);
            }).catch((err: any) => {
                console.error(err);
                toast.dismiss();
                setFullscreenLoading(false);
            });
        }
    }, []);

    useEffect(() => {
        setProblems(general.checkProblems(loanapplication, auth, objectCategorySequence));
    }, [loanapplication]);

    useEffect(() => {
        let results = urlParamsLoader.load(loanapplication);

        setSearchParamSegment(results.urlParams.segment);
        setSearchParamType(results.urlParams.type);
        setSearchParamCategory(results.urlParams.category);
        setSearchParamCaseObjectNew(results.urlParams.caseObjectNew);
        setSearchParamPurchaseSum(results.urlParams.purchaseSum);
        setSearchParamDownPaymentSum(results.urlParams.downPaymentSum);
        setSearchParamContractlengthmonths(results.urlParams.contractlengthmonths);
        setSearchParamRegnumber(results.urlParams.regnumber);
        setSearchParamObjectyear(results.urlParams.objectyear);
        setSearchParamObjectmake(results.urlParams.objectmake);
        setSearchParamObjectmodel(results.urlParams.objectmodel);
        setSearchParamDueday(results.urlParams.dueday);
        setSearchParamComment(results.urlParams.comment);
        setSearchParamCustomerPhone(results.urlParams.customerPhone);
        setSearchParamCustomerEmail(results.urlParams.customerEmail);
    }, []);

    useEffect(() => {
        const category = general.stringCategoryToIds((uniqueCategories ?? []), (loanapplication?.category ?? ""));
        const makeId = category?.split("|")[0];
        const makeSequence = uniqueCategories?.find((c: any) => c.typeid == makeId)?.typeSequence;
        const insuranceFremtindCode = uniqueCategories?.find((c: any) => c.typeid == makeId)?.insuranceFremtindCode;
        if(makeSequence) setObjectCategorySequence(makeSequence);
        setObjectInsuranceFremtindCode(insuranceFremtindCode);
    }, [loanapplication.category, uniqueCategories]);

    useEffect(() => {
        if(auth.selectedAgreement == undefined){
            setNominalInterestRateIsDefault(true);
        }
    }, [auth.selectedAgreement]);

    useEffect(() => {
        if(loanapplication?.sendToEsign == false || loanapplication?.segment == "Privat"){
            loanapplication.setComment(loanapplication?.comment?.replace(sendToEsignRegex, ""));
        } else {
            if(loanapplication?.sendToEsign == true && loanapplication?.segment == "Bedrift" && !(sendToEsignRegex.test(loanapplication?.comment))){
                loanapplication.setComment((loanapplication?.comment ?? "") + (loanapplication?.comment != "" && loanapplication?.comment != undefined ? "\n\n" : "") + sendToEsignString);
            }
        }
    }, [loanapplication?.segment, loanapplication?.sendToEsign]);

    if (auth.flags.newApplication) {
        auth.flags.newApplication = false;
        clearForm();
    }

    return (
        <>
            <div className={styles.loanapplication + " " + "noprint"}>
                <FullscreenLoading show={fullscreenLoading} />
                {auth.loading ?
                    <div className={styles.loadingcontainer}>
                        <Spinner large={true} />
                    </div>
                :
                    <>
                        <Grid className={styles.grid}>
                            <GridRow>
                                <GridCol lg="6" md="12" sm="12">
                                    { loanapplication?.Sequence != undefined || loanapplication?.ID != undefined ?
                                        <ContentCard
                                            open={true}
                                            disableClick={true}
                                            //title={"Aktiv søknad"}
                                        >
                                            <div className={styles.loaninfoarea}>
                                                <h2>{loanapplication?.segment} - {loanapplication?.type} ({loanapplication?.Sequence || "Kladd"})</h2>
                                                <p><span>Sist endret:</span><span>{general.dateToDateTimeString(loanapplication?.updateDate) || ""}</span></p>
                                                { loanapplication?.salesPersonSequence != undefined ?
                                                    <p><span>Selgernummer:</span><span>{loanapplication?.salesPersonSequence}</span></p>
                                                : null }
                                            </div>
                                            <div className={styles.loancaseinfoarea}>
                                                { loanapplication?.Sequence != undefined ? 
                                                    <p className={styles.loanstatus}>Status: {loanapplication?.status}</p>
                                                : null }
                                                <p>
                                                    {loanapplication?.regnumber} {
                                                        loanapplication?.objectyear != undefined ||
                                                        loanapplication?.objectmake != undefined ||
                                                        loanapplication?.objectmodel != undefined ?
                                                            <span>({loanapplication?.objectyear} {loanapplication?.objectmake} {loanapplication?.objectmodel})</span>
                                                        : null
                                                    }
                                                </p>
                                                <div className={styles.warnings}>
                                                    { loanapplication?.status == "Hos kunde" ?
                                                        <>
                                                            <span className="material-symbols-outlined" style={{float: "left"}}>
                                                                info
                                                            </span>
                                                            <p>Saken avventer tilbakemelding fra kunde</p>
                                                        </>
                                                    : null }
                                                </div>
                                            </div>
                                            <div className={styles.loancasebuttons}>
                                                <TertiaryButton onClick={() => {
                                                    let accepted = window.confirm("Er du sikker på at du vil forkaste alle endringer og starte en ny søknad?");
                                                    if (accepted) {
                                                        auth.setFlag("newApplication", true);
                                                        clearForm();
                                                    }
                                                }}>Ny søknad</TertiaryButton>
                                            </div>
                                        </ContentCard>
                                    : null }
                                    <ContentCard
                                        open={!auth.selectedAgreement?.ID && (uniqueSegments.length > 1 || uniqueTypes.length > 1)}
                                        closeCard={auth.selectedAgreement?.ID}
                                        openCard={openCards}
                                        disableClick={uniqueSegments.length < 2 && uniqueTypes.length < 2}
                                        title={
                                            (uniqueSegments.length == 1 && uniqueTypes.length == 1) ?
                                                uniqueSegments[0]?.name + " - " + uniqueTypes[0]?.name
                                            :
                                                "Velg " +
                                                (uniqueSegments.length > 1 ? "kundetype " : "") +
                                                (uniqueSegments.length > 1 && uniqueTypes.length > 1 ? "& " : "") +
                                                (uniqueTypes.length > 1 ? "lån eller leasing" : "")
                                        }
                                        closedTitle={
                                            loanapplication?.segment + " - " + loanapplication?.type
                                        }
                                    >
                                        <CustomerSegmentSelector
                                            segments={uniqueSegments}
                                            types={uniqueTypes}
                                            selectedSegment={loanapplication?.segment}
                                            selectedType={loanapplication?.type}
                                            onChangeSegment={(segment: string) => {
                                                loanapplication.setSegment(segment);
                                                changeSingleSearchParam("sb1-kundetype", segment == "Privat" ? "PM" : "BM");
                                                setSearchParamSegment(segment == "Privat" ? "PM" : "BM");
                                            }}
                                            onChangeType={(type: string) => {
                                                loanapplication.setType(type);
                                                changeSingleSearchParam("sb1-typelaan", type == "Lån" ? "Laan" : "Leasing");
                                                setSearchParamType(type == "Lån" ? "Laan" : "Leasing");
                                            }}
                                            onChangeBoth={(segment: string, type: string) => {
                                                loanapplication.setType(type);
                                                loanapplication.setSegment(segment);
                                            }}
                                            isPossibleAgreement={(segment: string | undefined, type: string | undefined) => 
                                                isPossibleAgreement(
                                                    segment, 
                                                    type, 
                                                    loanapplication?.category, 
                                                    loanapplication?.caseObjectNew != undefined ? loanapplication?.caseObjectNew ? "Ny" : "Brukt" : undefined
                                                )
                                            }
                                        ></CustomerSegmentSelector>
                                    </ContentCard>
                                    <ContentCard
                                        open={!auth.selectedAgreement?.ID && uniqueCategories.length > 1}
                                        closeCard={auth.selectedAgreement?.ID}
                                        openCard={openCards}
                                        //disableClick={uniqueCategories.length < 2}
                                        title="Velg objektkategori"
                                        closedTitle={
                                            uniqueCategories.find((i: any) => {
                                                return i.value == loanapplication?.category
                                            })?.name ?? "Velg objektkategori"
                                        }
                                    >
                                        <TabSelector
                                            tabs={uniqueStates?.sort((a: any, b: any) => {return a?.value?.localeCompare(b?.value)})}
                                            selected={loanapplication?.caseObjectNew != undefined ? (loanapplication?.caseObjectNew ? "Ny" : "Brukt") : undefined}
                                            onClick={(value: string) => {
                                                if (value == "Ny") {
                                                    loanapplication.setCaseObjectNew(true);
                                                } else if (value == "Brukt") {
                                                    loanapplication.setCaseObjectNew(false);
                                                } else {
                                                    loanapplication.setCaseObjectNew(undefined);
                                                }
                                                changeSingleSearchParam("sb1-tilstand", value == "Ny" ? "ny" : "brukt");
                                                setSearchParamCaseObjectNew(value == "Ny" ? "ny" : "brukt");
                                            }}
                                            isPossibleTab={(value: string) => isPossibleAgreement(
                                                loanapplication?.segment, 
                                                loanapplication?.type, 
                                                loanapplication?.category, 
                                                value
                                            )}
                                        />
                                        <CategorySelector
                                            multicolumn={ true /*uniqueCategories.length > 6*/}
                                            categories={uniqueCategories}
                                            selectedCategory={loanapplication?.category}
                                            onChange={(category: string) => {
                                                loanapplication.setCategory(category);
                                                changeSingleSearchParam("sb1-produkt", category);
                                                setSearchParamCategory(category);
                                                loanapplication.setObjectyear(undefined);
                                                loanapplication.setObjectmake(undefined);
                                                loanapplication.setObjectmakeName(undefined);
                                                loanapplication.setObjectmodel(undefined);
                                                loanapplication.setObjectmodelName(undefined);
                                                loanapplication.setObjecttype(undefined);
                                                loanapplication.setObjecttypeName(undefined);
                                                changeSingleSearchParam("sb1-aarsmodell", "");
                                                setSearchParamObjectyear("");
                                                changeSingleSearchParam("sb1-merke", "");
                                                setSearchParamObjectmake("");
                                                changeSingleSearchParam("sb1-modell", "");
                                                setSearchParamObjectmodel("");
                                            }}
                                            isPossibleAgreement={(category: string | undefined) => 
                                                isPossibleAgreement(
                                                    loanapplication?.segment, 
                                                    loanapplication?.type, 
                                                    category, 
                                                    loanapplication?.caseObjectNew != undefined ? loanapplication?.caseObjectNew ? "Ny" : "Brukt" : undefined
                                                )
                                            }
                                        ></CategorySelector>
                                    </ContentCard>
                                    {/* <ContentCard
                                        open={!auth.selectedAgreement?.ID && uniqueStates.length > 1}
                                        closeCard={auth.selectedAgreement?.ID}
                                        openCard={openCards}
                                        disableClick={uniqueStates.length < 2}
                                        title="Velg tilstand"
                                        closedTitle={loanapplication?.caseObjectNew != undefined ? loanapplication?.caseObjectNew ? "Ny" : "Brukt" : "Velg tilstand"}
                                    >
                                        <CategorySelector
                                            categories={uniqueStates}
                                            selectedCategory={ loanapplication?.caseObjectNew != undefined ? loanapplication?.caseObjectNew ? "Ny" : "Brukt" : undefined }
                                            onChange={(category: string) => {
                                                if (category == "Ny") {
                                                    loanapplication.setCaseObjectNew(true);
                                                } else if (category == "Brukt") {
                                                    loanapplication.setCaseObjectNew(false);
                                                } else {
                                                    loanapplication.setCaseObjectNew(undefined);
                                                }
                                                changeSingleSearchParam("sb1-tilstand", category == "Ny" ? "ny" : "brukt");
                                                setSearchParamCaseObjectNew(category == "Ny" ? "ny" : "brukt");
                                            }}
                                            isPossibleAgreement={(caseObjectNew: string | undefined) => 
                                                isPossibleAgreement(
                                                    loanapplication?.segment, 
                                                    loanapplication?.type, 
                                                    loanapplication?.category, 
                                                    caseObjectNew
                                                )
                                            }
                                        ></CategorySelector>
                                    </ContentCard> */}
                                    { localStorage.getItem("objectbeforecalc") == "true" ?
                                        <CaseObjectCard
                                            disabled={!isReady()}
                                            open={isReady()}
                                            currentLoanapplication={loanapplication}
                                            foundVehicle={foundVehicle}
                                            setFoundVehicle={setFoundVehicle}
                                            selectedAgreement={auth.selectedAgreement}
                                            selectedDealer={auth.selectedDealer}
                                            regnumberChanged={(regnumber: string) => {
                                                changeSingleSearchParam("sb1-regnr", regnumber);
                                                setSearchParamRegnumber(regnumber);
                                            }}
                                            objectyearChanged={(objectyear: string | undefined) => {
                                                changeSingleSearchParam("sb1-aarsmodell", objectyear ?? "");
                                                setSearchParamObjectyear(objectyear ?? "");
                                            }}
                                            objectmakeChanged={(objectmake: string | undefined) => {
                                                changeSingleSearchParam("sb1-merke", objectmake ?? "");
                                                setSearchParamObjectmake(objectmake ?? "");
                                            }}
                                            objectmodelChanged={(objectmodel: string | undefined) => {
                                                changeSingleSearchParam("sb1-modell", objectmodel ?? "");
                                                setSearchParamObjectmodel(objectmodel ?? "");
                                            }}
                                            objectCategorySequence={objectCategorySequence}
                                            insuranceFremtindCode={objectInsuranceFremtindCode}
                                            //TODO: Trigge noe som henter de ny make-navnene.
                                        />
                                    : null }
                                </GridCol>
                            </GridRow>
                        </Grid>
                        <Calculator
                            ref={calculatorRef}
                            disabled={!isReady()}
                            open={isReady()}
                            nominalInterestRateIsDefault={nominalInterestRateIsDefault}
                            resetNominalInterestRateIsDefault={() => {
                                setNominalInterestRateIsDefault(true);
                            }}
                            onChangesMade={setCalculatorChangesMade}
                            fieldChanged={(field: string, value: any) => {
                                switch (field) {
                                    case "nominalInterestRate":
                                        setNominalInterestRateIsDefault(false);
                                        break;
                                    case "purchaseSum":
                                        changeSingleSearchParam("sb1-pris", value);
                                        setSearchParamPurchaseSum(value);
                                        break;
                                    case "downPaymentSum":
                                        changeSingleSearchParam("sb1-egenkapital", value);
                                        setSearchParamDownPaymentSum(value);
                                        break;
                                    case "contractlengthmonths":
                                        changeSingleSearchParam("sb1-nedbetalingstid", (Math.round((Number(value) / 12) * 10) / 10).toString());
                                        setSearchParamContractlengthmonths((Math.round((value / 12) * 10) / 10).toString());
                                        break;
                                    case "dueday":
                                        changeSingleSearchParam("sb1-forfallsdag", value);
                                        setSearchParamDueday(value);
                                        break;
                                }
                            }}
                            printClicked={() => {
                                toast.dismiss();
                                setTriggerPrint(triggerPrint + 1);
                            }}
                        ></Calculator>
                        
                        <Grid className={styles.grid}>
                            <GridRow>
                                <GridCol lg="6" md="12" sm="12">
                                    { auth?.selectedDealer?.DebtInsuranceEnabled && loanapplication?.segment == "Privat" ?
                                        <LoanInsuranceCard
                                            disabled={!isReady()}
                                            open={isReady()}
                                            currentLoanapplication={loanapplication}
                                        /> 
                                    : null }
                                    {
                                        general.showArea("tillatVarianter", auth.selectedAgreement, auth.selectedDealer, loanapplication) &&
                                        loanapplication?.uniqueVariants.length > 1
                                    ?
                                        <ContentCard
                                            open={loanapplication?.uniqueVariants.length > 1}
                                            title={(loanapplication?.type == "Lån" ? "Låne" : "Leasing") + "variant"}
                                        >
                                            <SelectorList
                                                options={loanapplication?.uniqueVariants
                                                    .map((i:any) => ({
                                                        label: i.name,
                                                        value: i.id,
                                                    }))
                                                    .sort((a:any, b:any) => {
                                                        
                                                        if (a.label.toLowerCase() === "standard") return -1;
                                                        if (b.label.toLowerCase() === "standard") return 1;
                                                        return a.label.localeCompare(b.label);
                                                    })}
                                                onChange={value => {
                                                    loanapplication.setVariant(value as string);
                                                }}
                                                value={[loanapplication?.variant]}
                                            ></SelectorList>
                                        </ContentCard>
                                    : null}
                                    { localStorage.getItem("objectbeforecalc") != "true" ?
                                        <CaseObjectCard
                                            disabled={!isReady()}
                                            open={isReady()}
                                            currentLoanapplication={loanapplication}
                                            foundVehicle={foundVehicle}
                                            setFoundVehicle={setFoundVehicle}
                                            selectedAgreement={auth.selectedAgreement}
                                            selectedDealer={auth.selectedDealer}
                                            regnumberChanged={(regnumber: string) => {
                                                changeSingleSearchParam("sb1-regnr", regnumber);
                                                setSearchParamRegnumber(regnumber);
                                            }}
                                            objectyearChanged={(objectyear: string | undefined) => {
                                                changeSingleSearchParam("sb1-aarsmodell", objectyear ?? "");
                                                setSearchParamObjectyear(objectyear ?? "");
                                            }}
                                            objectmakeChanged={(objectmake: string | undefined) => {
                                                changeSingleSearchParam("sb1-merke", objectmake ?? "");
                                                setSearchParamObjectmake(objectmake ?? "");
                                            }}
                                            objectmodelChanged={(objectmodel: string | undefined) => {
                                                changeSingleSearchParam("sb1-modell", objectmodel ?? "");
                                                setSearchParamObjectmodel(objectmodel ?? "");
                                            }}
                                            objectCategorySequence={objectCategorySequence}
                                            insuranceFremtindCode={objectInsuranceFremtindCode}
                                        />
                                    : null }
                                    <CaseCustomerCard
                                        disabled={!isReady()}
                                        open={isReady()}
                                        currentLoanapplication={loanapplication}
                                        onCustomerFound={(customer: any) => {
                                            if (customer?.Personid?.length == 11) {
                                                let birthdate = general.socialSecurityNumberToBirthdate(customer?.Personid);

                                                // If birthdate is not an invalid date, set birthdate
                                                if (moment(birthdate).isValid()) {

                                                    // If customer is under 23, set insurance to low age
                                                    let age = moment().diff(birthdate, 'years');

                                                    if (age < 23) {
                                                        loanapplication.setInsuranceLowAge(true);
                                                    } else {
                                                        loanapplication.setInsuranceLowAge(false);
                                                    }
                                                }
                                            }
                                        }}
                                        // onCustomerEmailChanged={(email: string) => {
                                        //     changeSingleSearchParam("sb1-epost", email);
                                        //     setSearchParamCustomerEmail(email);
                                        // }}
                                        // onCustomerPhoneChanged={(phone: string) => {
                                        //     changeSingleSearchParam("sb1-mobil", phone);
                                        //     setSearchParamCustomerPhone(phone);
                                        // }}
                                    />
                                    { auth?.selectedDealer?.ObjectSupplierRequired ?
                                        <ObjectSupplierCard
                                            disabled={!isReady()}
                                            open={isReady()}
                                            currentLoanapplication={loanapplication}
                                        /> 
                                    : null }
                                    <CaseInsuranceCard
                                        disabled={!isReady()}// || !general.showArea("forsikring", auth.selectedAgreement, auth.selectedDealer, loanapplication)}
                                        open={isReady()}// && general.showArea("forsikring", auth.selectedAgreement, auth.selectedDealer, loanapplication)}
                                        currentLoanapplication={loanapplication}
                                        objectCategorySequence={objectCategorySequence}
                                        insuranceFremtindCode={objectInsuranceFremtindCode}
                                    />
                                    <ContentCard disabled={!isReady()} open={isReady()} title="Kommentar">
                                        { loanapplication?.segment == "Bedrift" ? 
                                            <>
                                                <RadioButtonInputGroup
                                                    label="Skal saken sendes rett til e-signering etter vurdering?"
                                                    //tooltip=""
                                                    name="sendToEsign"
                                                    onChange={e => {
                                                        loanapplication.setSendToEsign(e.target.value == "true");
                                                    }}
                                                    selectedValue={loanapplication?.sendToEsign != undefined ? loanapplication?.sendToEsign.toString() : ""}
                                                >
                                                    {inputProps => (
                                                        <RadioSwitch
                                                            leftLabel="Ja"
                                                            leftValue="true"
                                                            rightLabel="Nei"
                                                            rightValue="false"
                                                            {...inputProps}
                                                        />
                                                    )}
                                                </RadioButtonInputGroup>
                                                &nbsp;
                                            </>
                                        : null }
                                        <InputGroup
                                            label="Kommentar til søknaden"
                                            //tooltip="REPLACE ME PLEASE - Kommentar til søknaden"
                                            //fieldMessage={showErrors ? 'Du må skrive noe lurt' : null}
                                        >
                                            <TextArea
                                                rows={4}
                                                value={loanapplication?.comment || ""}
                                                onChange={e => {
                                                    loanapplication.setComment(e.target.value);

                                                    changeSingleSearchParam("sb1-kommentar", e.target.value);
                                                    setSearchParamComment(e.target.value);
                                                }}
                                            />
                                        </InputGroup>
                                    </ContentCard>
                                    <ContentCard disabled={!isReady()} open={isReady()} title="Innsending av søknad">
                                        &nbsp;
                                        <Grid className={styles.grid}>
                                            <GridRow>
                                                <GridCol sm="6">
                                                    <SecondaryButton onClick={() => {
                                                        let accepted = window.confirm("Er du sikker på at du vil nullstille søknaden og forkaste alle data?");
                                                        if (accepted) {
                                                            clearForm();
                                                        }
                                                    }}>Nullstill</SecondaryButton>
                                                </GridCol>
                                                <GridCol sm="6">
                                                    <SecondaryButton
                                                        disabled={
                                                            sendingLoanApplication || 
                                                            loanapplication?.Sequence != undefined ||
                                                            general.showArea("flyttKundeinfo", auth.selectedAgreement, auth.selectedDealer, loanapplication)
                                                        }
                                                        onClick={() => {
                                                            if (loanapplication?.ID != undefined) {
                                                                updateLoanapplication("Kladd");
                                                            } else {
                                                                sendLoanapplication("Kladd");
                                                            }
                                                        }}
                                                    >{loanapplication?.ID == undefined ? "Lagre kladd" : "Oppdater kladd"}</SecondaryButton>
                                                </GridCol>
                                            </GridRow>
                                        </Grid>
                                        &nbsp;
                                        <ActionButton
                                            disabled={sendingLoanApplication || problems.length > 0}
                                            onClick={() => {
                                                if (problems.length == 0){
                                                    if (loanapplication?.Sequence != undefined) {
                                                        updateLoanapplication();
                                                    } else {
                                                        sendLoanapplication();
                                                    }
                                                }
                                            }}
                                        >
                                            { loanapplication?.Sequence != undefined ? "Oppdater søknad" : "Send søknad" }
                                        </ActionButton>
                                        { problems.length > 0 ?
                                            <div className={styles.problems}>
                                                { problems.map((problem, key) =>
                                                    <p key={key}>{problem}</p>
                                                )}
                                            </div>
                                        :
                                            <p>Send inn søknad på vegne av {auth.selectedDealer?.CompanyName}</p>
                                        }
                                    </ContentCard>
                                </GridCol>
                            </GridRow>
                        </Grid>
                    </>
                }
            </div>
            <CalculationPrint
                currentLoanapplication={loanapplication}
                calculationResult={loanapplication?.calculationResult}
                CompanyName={auth.selectedDealer?.CompanyName}
                Name={auth.user?.userdata?.Name || ""}
                Phone={auth.user?.userdata?.Phone || ""}
                triggerPrint={triggerPrint}
                showArea={(area) => general.showArea(area, auth.selectedAgreement, auth.selectedDealer, loanapplication)}
                segment={loanapplication.segment}
                caseObjectIsNew={loanapplication.caseObjectNew}
                type={loanapplication.type}
                category={loanapplication.category?.toLowerCase().split("|")[0]}
                objectMakeName={loanapplication.objectmakeName ?? undefined}
                objectModelName={loanapplication.objectmodelName ?? undefined}
                objectYear={loanapplication.objectyear ?? undefined}
            />
        </>
    );
}

export default Loanapplication;