import React, { useEffect, useRef, useState, } from "react";
import { useAuth } from "../../../Auth";
import { useAxios } from '../../../AxiosHandler';
import { useLoanapplication } from "../../../LoanapplicationContext";

import styles from './LoanOverview.module.scss';
import ContentCard from "../../Shared/ContentCard/ContentCard";
import { Tab, TabGroup } from "@sb1/ffe-tabs-react";
import { Input, InputGroup } from "@sb1/ffe-form-react";
import { Grid, GridCol, GridRow } from "@sb1/ffe-grid-react";
import Dropdown from "@sb1/ffe-dropdown-react";
import { Accordion, AccordionItem } from "@sb1/ffe-accordion-react";
import ArchiveItem from "../../Shared/ArchiveItem/ArchiveItem";
import { Case } from "../../../model/Case";
import Pill from "../../Shared/Pill/Pill";
import SplitButton from "../../Shared/SplitButton/SplitButton";
import SearchField from "../../Shared/SearchField/SearchField";
import FileUpload from "../../Shared/FileUpload/FileUpload";
import {FileList,File} from  "../../Shared/FileList/FileList";
import { Loanapplication as LoanapplicationModel} from "../../../model/Loanapplication";
import Spinner from "@sb1/ffe-spinner-react";
import { toast } from 'react-toastify';
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import moment from "moment";
import general from "../../../functions/general";
import { ActionButton, SecondaryButton } from "@sb1/ffe-buttons-react";
import CalculationPrint from "../Loanapplication/Partials/CalculationPrint/CalculationPrint";
import { formatRegistrationNumber } from "../../../functions/dataSanitation";

function Loanoverview() {
    const auth = useAuth();
    const loanapplication = useLoanapplication();
    const axiosHandler = useAxios();
    const axios = axiosHandler.axios;
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    
    const [insuranceCompanies, setInsuranceCompanies] = useState<any>([]);
    const [insuranceCompaniesFremtind, setInsuranceCompaniesFremtind] = useState<any>([]);
    const [insuranceBonusOptions, setInsuranceBonusOptions] = useState<any>([]);

    const [allCases, setAllCases] = useState<Array<Case>>([]);
    const [filteredCases, setFilteredCases] = useState<Array<Case>>([]);
    const [shownCases, setShownCases] = useState<Array<Case>>([]);
    const [showAllCases, setShowAllCases] = useState<boolean>(false);
    const [showCaseDetails, setShowCaseDetails] = useState<string>("attachments");
    const [selectedCase, setSelectedCase] = useState<Case | null>(null);
    const [archiveOpen, setArchiveOpen] = useState<boolean>(true);
    const [activeItem, setActiveItem] = useState<string | number | undefined>(undefined);

    const [selectedCaseChassisNumber, setSelectedCaseChassisNumber] = useState<string | undefined>(undefined);
    const [selectedCaseInsuranceBonusSource, setSelectedCaseInsuranceBonusSource] = useState<string | undefined>(undefined);
    const [selectedCaseInsuranceBonusSourceRegnr, setSelectedCaseInsuranceBonusSourceRegnr] = useState<string | undefined>(undefined);
    const [selectedCaseRegnr, setSelectedCaseRegnr] = useState<string | undefined>(undefined);

    const [showMore, setShowMore] = useState<number>(0);
    const [documentType, setDocumentType] = useState<number>(0);
    const [documentList, setDocumentList] = useState<Array<File>>([]);
    const [documentListLoading, setDocumentListLoading] = useState(false);

    const [searchValue, setSearchValue] = useState<string>("");
    const [statusValue, setStatusValue] = useState<string>("Alle aktive saker");

    const fetchWaitingTimer = useRef<number>(0);

    const [loading, setLoading] = useState<boolean>(false);
    const [loadingSingle, setLoadingSingle] = useState<boolean>(false);

    const [activateInsuranceLoading, setActivateInsuranceLoading] = useState<boolean>(false);
    const [insuranceActivated, setInsuranceActivated] = useState<boolean>(false);

    const [showExtraInfo, setShowExtraInfo] = useState(false);
    
    const [triggerPrint, setTriggerPrint] = useState<number>(0);

    function isCompany(caseObject?: Case) {
        if (
            caseObject?.CustomerOrganizationNumber?.length == 9 || 
            caseObject?.CustomerSector == "8200"
        ) {
            return true;
        } else {
            return false;
        }
    }

    function isAtKunde(caseObject?: Case) {
        if (isCompany(caseObject)) return false;
        if (
            (
                caseObject?.IsSentToESign == true &&
                caseObject?.Status == "Dokumenter sendt"
            ) || (
                caseObject?.IsReady == false &&
                caseObject?.Incoming == true &&
                caseObject?.Status == "Kontroll"
            )
        ) {
            return true;
        } else {
            return false;
        }
    }
    
    function isAtFinans(caseObject?: Case) {
        if (
            caseObject?.Incoming == false &&
            !isAtKunde(caseObject) &&
            (
                caseObject?.Status == "Kontroll" ||
                caseObject?.Status == "Godkjent" ||
                caseObject?.Status == "Dokumenter sendt" ||
                caseObject?.Status == "Dokumenter signert" ||
                caseObject?.Status == "Overført"
            )
        ) {
            return true;
        } else {
            return false;
        }
    }

    function idCategoryToStrings(categoryIds: string) {
        let uniqueCategories = auth.uniqueCategories;
        if (uniqueCategories == undefined || uniqueCategories?.length == 0) {
            uniqueCategories = general.getUniqueFilters(auth.selectedDealer).uniqueCategories;
            auth.setUniqueCategories(uniqueCategories);
        }
        if (categoryIds.length == 73) {
            let categoryId = categoryIds?.split("|")[0];
            let variantId = categoryIds?.split("|")[1];
            let categoryName = uniqueCategories?.find((i: any) => i.typeid == categoryId && i.variantid == variantId)?.value.split("|")[0];
            let variantName = uniqueCategories?.find((i: any) => i.typeid == categoryId && i.variantid == variantId)?.value.split("|")[1];
            return categoryName + "|" + variantName;
        } else {
            return categoryIds;
        }
    }

    function fetchCases() {
        setLoading(true);
        setAllCases([]);
        setFilteredCases([]);
        setShownCases([]);
        let fetchingTimer = window.setTimeout(() => {
            toast("Henting av saker pågår. Vennligst vent ...", { autoClose: false });
        }, 4000);
        fetchWaitingTimer.current = fetchingTimer;
        axios.single({
            url: "/api/cases" + ("?dealersequence=" + auth.selectedDealer.Sequence) + (showAllCases ? "" : ("&usersequence=" + auth.user.userdata?.Sequence)),
            method: "GET",
            headers: {
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
            }
        }).then((res: any) => {
            setLoading(false);
            window.clearTimeout(fetchWaitingTimer.current);
            toast.dismiss();

            if (res.data == undefined) { toast.error("Henting av saker feilet. Vennligst prøv igjen"); return; }
            if (res.data != undefined && res.data == "") { toast.info("Fant ingen saker"); return; }
            if ((typeof res.data?.filter) != "function") { toast.error("Henting av saker feilet. Vennligst prøv igjen"); return; }
            setAllCases(res.data);
        }).catch((err: any) => {
            console.log(err);
            setLoading(false);
            window.clearTimeout(fetchWaitingTimer.current);
            toast.dismiss();
            toast.error("Henting av saker feilet. Vennligst prøv igjen");
        });
    }

    async function fetchCustomer(caseObject: Case) {
        if (
            caseObject.CustomerOrganizationNumber != undefined &&
            caseObject.CustomerOrganizationNumber != null &&
            (
                caseObject.CustomerOrganizationNumber?.length == 9 ||
                (caseObject.CustomerSector != "8500" && // Private
                caseObject.CustomerSector != "8200") // Enkeltpersonforetak
            )
        ) { // If orgnumber, search for company
            await axios.single({
                url: "/api/companies/" + (caseObject.CustomerOrganizationNumber?.length == 9 ? caseObject.CustomerOrganizationNumber : caseObject.CustomerSequence),
                method: "GET",
                headers: {
                    "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
                }
            }).then((res2: any) => {
                caseObject.caseCustomer = {
                    Sequence: res2.data.Sequence,
                    Name: res2.data.CompanyName,
                    Mobile: res2.data.Mobile,
                    Email: res2.data.Email,
                    OrganizationNumber: (res2.data.Sector != "8500" && res2.data.Sector != "8200") ? res2.data.OrgNumber : res2.data.Personid
                };
                setSelectedCase(caseObject);
                toast.dismiss();
                setLoadingSingle(false);
            }).catch((err: any) => {
                console.log(err);
                toast.dismiss();
                setLoadingSingle(false);
                toast.error("Henting av kunde feilet! Vennligst prøv igjen");
            });
        } else if (
            caseObject.CustomerOrganizationNumber != undefined &&
            caseObject.CustomerOrganizationNumber != null &&
            (
                caseObject.CustomerOrganizationNumber?.length == 11 ||
                caseObject.CustomerSector == "8500" || // Private
                caseObject.CustomerSector == "8200" // Enkeltpersonforetak
            )
        ) { // If personalnumber, search for person
            await axios.single({
                url: "/api/persons/" + (caseObject.CustomerOrganizationNumber?.length == 11 ? caseObject.CustomerOrganizationNumber : caseObject.CustomerSequence),
                method: "GET",
                headers: {
                    "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
                }
            }).then((res2: any) => {
                caseObject.caseCustomer = {
                    Sequence: res2.data.Sequence,
                    Name: res2.data.LastName + ", " + res2.data.FirstName,
                    Mobile: caseObject?.caseCustomer?.Mobile || res2.data.Mobile,
                    Email: caseObject?.caseCustomer?.Email || res2.data.Email,
                    OrganizationNumber: (res2.data.Sector == "8500" || res2.data.Sector == "8200") ? res2.data.Personid : res2.data.OrgNumber
                };
                setSelectedCase(caseObject);
                toast.dismiss();
                setLoadingSingle(false);
            }).catch((err: any) => {
                console.log(err);
                toast.dismiss();
                toast.error("Henting av kunde feilet! Vennligst prøv igjen");
                setActiveItem(0);
                setLoadingSingle(false);
            });
        } else { // If neither, just set the case
            toast.dismiss();
            setSelectedCase(caseObject);
            setLoadingSingle(false);
        }
    }

    async function fetchCoCustomer(caseObject: Case) {
        if (
            caseObject.CoBuyerOrganizationNumber?.length == 9 ||
            (caseObject.CoBuyerSector != "8500" && // Private
            caseObject.CoBuyerSector != "8200") // Enkeltpersonforetak
        ) { // If orgnumber, search for company
            await axios.single({
                url: "/api/companies/" + (caseObject.CoBuyerOrganizationNumber?.length == 9 ? caseObject.CoBuyerOrganizationNumber : caseObject.CoBuyerSequence),
                method: "GET",
                headers: {
                    "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
                }
            }).then((res2: any) => {
                caseObject.caseCoCustomer = {
                    Sequence: res2.data.Sequence,
                    Name: res2.data.CompanyName,
                    Mobile: res2.data.Mobile,
                    Email: res2.data.Email,
                    OrganizationNumber: (res2.data.Sector != "8500" && res2.data.Sector != "8200") ? res2.data.OrgNumber : res2.data.Personid
                };
                setSelectedCase(caseObject);
                toast.dismiss();
                setLoadingSingle(false);
            }).catch((err: any) => {
                console.log(err);
                toast.dismiss();
                setLoadingSingle(false);
                toast.error("Henting av kunde feilet! Vennligst prøv igjen");
            });
        } else if (
            caseObject.CoBuyerOrganizationNumber?.length == 11 ||
            caseObject.CoBuyerSector == "8500" || // Private
            caseObject.CoBuyerSector == "8200" // Enkeltpersonforetak
        ) { // If personalnumber, search for person
            await axios.single({
                url: "/api/persons/" + (caseObject.CoBuyerSocialSecurityNumber?.length == 11 ? caseObject.CoBuyerSocialSecurityNumber : caseObject.CoBuyerSequence),
                method: "GET",
                headers: {
                    "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
                }
            }).then((res2: any) => {
                caseObject.caseCoCustomer = {
                    Sequence: res2.data.Sequence,
                    Name: res2.data.LastName + ", " + res2.data.FirstName,
                    Mobile: res2.data.Mobile,
                    Email: res2.data.Email,
                    OrganizationNumber: (res2.data.Sector == "8500" || res2.data.Sector == "8200") ? res2.data.Personid : res2.data.OrgNumber
                };
                setSelectedCase(caseObject);
                toast.dismiss();
                setLoadingSingle(false);
            }).catch((err: any) => {
                console.log(err);
                toast.dismiss();
                toast.error("Henting av kunde feilet! Vennligst prøv igjen");
                setActiveItem(0);
                setLoadingSingle(false);
            });
        } else { // If neither, just set the case
            toast.dismiss();
            setSelectedCase(caseObject);
            setLoadingSingle(false);
        }
    }

    function fetchSingleCase(caseSequence: string | number) {
        setLoadingSingle(true);
        setInsuranceActivated(false);
        toast.info("Henter sak ...", { autoClose: false });
        axios.single({
            url: "/api/cases/" + caseSequence,
            method: "GET",
            headers: {
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
            }
        }).then(async (res: any) => {
            setSelectedCase(res.data);
            await fetchCustomer(res.data);

            if (
                (res?.data?.CoBuyerSequence != null && res?.data?.CoBuyerSequence != undefined) ||
                (res?.data?.CoBuyerOrganizationNumber != null && res?.data?.CoBuyerOrganizationNumber != undefined) ||
                (res?.data?.CoBuyerSocialSecurityNumber != null && res?.data?.CoBuyerSocialSecurityNumber != undefined)
            ) {
                await fetchCoCustomer(res.data);
            }

            setDocumentType(0);
            
            fetchSingleCaseDocuments(res.data.sequence);
            
            let documents: Array<File> = [];
    
            if (res.data != "") {
                res.data?.DocumentSets?.forEach((item:any) => {
                    if (item.IsUploaded) {
                        documents.push({
                            name: item.documents[0].DocumentDefinitionName,
                            value: item.Sequence,
                            datetime: item.GeneratedDate || "",
                        });
                    }
                });
            }

            setSelectedCaseChassisNumber(res.data?.FinanceCaseExtension?.[0]?.ChassisNumber || "");
            setSelectedCaseInsuranceBonusSource(res.data?.FinanceCaseExtension?.InsuranceBonusSource || "");
            setSelectedCaseInsuranceBonusSourceRegnr(res.data?.FinanceCaseExtension?.InsuranceBonusSourceRegnr || "");
            
            setDocumentList(documents);
        }).catch((err: any) => {
            console.log(err);
            setLoadingSingle(false);
            toast.dismiss();
            toast.error("Henting av sak feilet! Vennligst prøv igjen");
            setActiveItem(0);
        });
    }

    function fetchSingleCaseDocuments(caseSequence: string | undefined) {
        if (caseSequence != undefined && caseSequence != "") {
            setLoadingSingle(true);
            setDocumentListLoading(true);
            axios.single({
                url: "/api/casedocuments/bysequenceid/" + caseSequence,
                method: "GET",
                headers: {
                    "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
                }
            }).then((res: any) => {
                let documents: Array<File> = [];
    
                if (res.data != "") {
                    res.data?.forEach((item:any) => {
                        if (item.IsUploaded) {
                            documents.push({
                                name: item.document[0].DocumentDefinitionName,
                                value: item.Sequence,
                                datetime: item.GeneratedDate || "",
                            });
                        }
                    });
                }
                
                setDocumentList(documents);
                setDocumentListLoading(false);
            }).catch((err: any) => {
                console.log(err);
                setLoadingSingle(false);
                toast.dismiss();
                toast.error("Henting av saksdokumenter feilet! Vennligst prøv igjen");
                setActiveItem(0);
            });
        }
    }

    function setCaseReady(caseSequence: string | undefined, caseID: number | undefined, ready: boolean) {
        if (!caseSequence && !caseID) return;
        if (ready) {
            toast.info("Sender sak til finans ...", { autoClose: false });
        } else {
            toast.info("Tar tilbake sak ...", { autoClose: false });
        }
        axios.single({
            url: "/api/cases/" + (caseSequence || caseID),
            method: "PUT",
            data: {
                DealerSequence: auth.selectedDealer?.Sequence,
                IsReady: ready,
                AgreementSequence: selectedCase?.AgreementSequence,
                CustomerSequence: selectedCase?.CustomerSequence,
                CoBuyerSequence: selectedCase?.CoBuyerSequence,
                DoSetBackToDefaulIncomingWorkGroupOnDealer: true,
                "Calculation": [ ],
                "CaseObjects": [ ],
            },
            headers: {
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
            }
        }).then((res: any) => {
            toast.dismiss();
            fetchSingleCase(caseSequence || caseID || 0);
            fetchCases();
        }).catch((err: any) => {
            console.log(err);
            toast.dismiss();
            if (ready) {
                toast.error("Sending av sak feilet!");
            } else {
                toast.error("Tilbakekalling av sak feilet!");
            }
        });
    }

    function cancelCase(caseSequence: string | undefined, caseID: number | undefined) {
        if (!caseSequence && !caseID) return;
        toast.info("Kansellerer sak ...", { autoClose: false });
        axios.single({
            url: "/api/cases/" + (caseSequence || caseID),
            method: "PUT",
            data: {
                DealerSequence: auth.selectedDealer?.Sequence,
                CancelReason: "Annullert",
                "Calculation": [ ],
                "CaseObjects": [ ],
            },
            headers: {
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
            }
        }).then((res: any) => {
            toast.dismiss();
            setSelectedCase(null);
            fetchCases();
        }).catch((err: any) => {
            console.log(err);
            toast.dismiss();
            toast.error("Kansellering av sak feilet!");
        });
    }

    function sendCaseToSigning(caseSequence: string | undefined, caseID: number | undefined) {
        if (!caseSequence && !caseID) return;
        toast.info("Sender til signering ...", { autoClose: false });
        axios.single({
            url: "/api/cases/esign/" + (caseSequence || caseID),
            method: "POST",
            headers: {
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
            }
        }).then((res: any) => {
            toast.dismiss();
            fetchSingleCase(caseSequence || caseID || 0);
            fetchCases();
            if(!res.data.error){
                toast.info("Sak sendt til signering!");
            }
        }).catch((err: any) => {
            console.log(err);
            toast.dismiss();
            toast.error("Sending av sak feilet!");
        });
    }

    function getCaseSigningLink(caseSequence: string | undefined, caseID: number | undefined) {
        if (!caseSequence && !caseID) return;
        toast.info("Henter signeringslink ...", { autoClose: false });
        axios.single({
            url: "/api/casedocuments/esignatures/" + (caseSequence || caseID),
            method: "GET",
            headers: {
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
            }
        }).then((res: any) => {
            toast.dismiss();
            
            if (res.data?.[0]?.SignatureURL) {
                toast.success(res.data?.[0]?.SignatureURL, {
                    position: "top-center",
                    autoClose: false,
                    hideProgressBar: true,
                    closeOnClick: false,
                    draggable: false,
                    icon: <span className="material-symbols-outlined" style={{ cursor: "pointer" }}>content_copy</span>,
                    onClick: () => {
                        navigator.clipboard.writeText(res.data?.[0]?.SignatureURL);
                        toast.info("Link kopiert til utklippstavle", {
                            autoClose: 3000,
                        });
                    }
                });
            }
        }).catch((err: any) => {
            console.log(err);
            toast.dismiss();
            toast.error("Henting av signeringslink feilet!");
        });
    }

    function getCaseSigningDocuments(caseSequence: string | undefined, caseID: number | undefined) {
        if (!caseSequence && !caseID) return;
        toast.info("Henter signeringsdokumenter ...", { autoClose: false });
        axios.single({
            url: "/api/cases/generatesigningdocuments",
            method: "POST",
            headers: {
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
            },
            data: {
                FinanceCaseSequence: (caseSequence || caseID)
            }
        }).then((res: any) => {
            toast.dismiss();

            const linkSource = `data:application/pdf;base64,${res.data.DocumentDataString}`;
            const downloadLink = document.createElement("a");
            const fileName = "test.pdf";
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        }).catch((err: any) => {
            console.log(err);
            toast.dismiss();
            toast.error("Henting av dokumenter feilet!");
        });
    }

    function fetchInsuranceBonus() {
        axios.single({
            url: "/api/fremtind/kodeverk/bonus",
            method: "GET",
            headers: {
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
            }
        }).then((res: any) => {
            if (res.data.bonusLevels != undefined) {
                setInsuranceBonusOptions(res.data.bonusLevels.filter((x: any) => x.isActive).map((x: any) => {
                    return {
                        value: x.key,
                        label: x.caption
                    }
                }));
            } else {
                toast.error("Innlasting av bonus feilet!");
                if (res.data.error != undefined) {
                    toast.error("Fremtind | " + res.data.error.errorCode + ": " + res.data.error.errorMessage);
                }
            }
        }).catch((err: any) => {
            console.log(err);
            toast.error("Innlasting av bonus feilet!");
        });
    }

    function fetchInsuranceCompanies() {
        axios.single({
            url: "/api/company/insurancecompanies",
            method: "GET",
            headers: {
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
            }
        }).then((res: any) => {
            setInsuranceCompanies(res.data?.map((x: any) => {
                return {
                    value: x.Sequence,
                    label: x.CompanyName
                }
            }));
        }).catch((err: any) => {
            console.log(err);
            toast.error("Innlasting av forsikringsselskaper feilet!");
        });
    }
        
    function fetchInsuranceCompaniesFremtind() {
        axios.single({
            url: "/api/fremtind/kodeverk/forsikringsselskap",
            method: "GET",
            headers: {
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
            }
        }).then((res: any) => {
            setInsuranceCompaniesFremtind(res.data?.selskap?.map((x: any) => {
                return {
                    value: x.kundenummer,
                    label: x.navn
                }
            }));
        }).catch((err: any) => {
            console.log(err);
            toast.error("Innlasting av forsikringsselskaper feilet!");
        });
    }

    function activateInsurance() {
        setActivateInsuranceLoading(true);
        if (!selectedCase?.InsuranceCases?.[0]?.ID) return;
        axios.single({
            url: "/api/fremtind/bil/kjop",
            method: "POST",
            headers: {
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"]),
                "X-Request-Id": general.generateXRequestId(),
            },
            data: {
                "ID": selectedCase?.InsuranceCases?.[0]?.ID,
                "dealerSequence": auth.selectedDealer?.Sequence,
                "regnr": selectedCaseRegnr || null,
                "chassisNummer": selectedCaseChassisNumber || null,
                "bonusFraSelskap": selectedCase?.FinanceCaseExtension?.[0]?.InsuranceBonusSource || null,
                "bonusFraRegnr": formatRegistrationNumber(selectedCase?.FinanceCaseExtension?.[0]?.InsuranceBonusSourceRegnr || "", true) || null
            }
        }).then((res: any) => {
            toast.dismiss();
            if(res.data?.Message != undefined){
                toast.error(res.data?.Message)
            } else {
                setInsuranceActivated(true);
                toast.success("Aktivering sendt!");
            }
            setActivateInsuranceLoading(false);
        }).catch((err: any) => {
            toast.dismiss();
            console.log(err);
            toast.error("Kunne ikke aktivere forsikring!");
            toast.error(err.response.data);
            setActivateInsuranceLoading(false);
            setInsuranceActivated(false);
        });
    }

    function filterCases() {
        let tempfilter = allCases.filter((item) => {
            let textMatch = false;
            if (searchValue == "") textMatch = true;
            if (item.sequence?.toString().includes(searchValue)) textMatch = true;
            if (item.CustomerName?.toLowerCase().includes(searchValue.toLowerCase())) textMatch = true;
            if (item.CaseObjects?.[0]?.RegistrationNumber?.toLowerCase().includes(searchValue.toLowerCase())) textMatch = true;
            if (item.CaseObjects?.[0]?.ChassisNumber?.toLowerCase().includes(searchValue.toLowerCase())) textMatch = true;
            if (item.CaseObjects?.[0]?.MakeName?.toLowerCase().includes(searchValue.toLowerCase())) textMatch = true;
            if (item.CaseObjects?.[0]?.ModelName?.toLowerCase().includes(searchValue.toLowerCase())) textMatch = true;
            if ((item.CaseObjects?.[0]?.MakeName + " " + item.CaseObjects?.[0]?.ModelName)?.toLowerCase().includes(searchValue.toLowerCase())) textMatch = true;
            if (item?.CustomerOrganizationNumber?.includes(searchValue)) textMatch = true;

            let statusMatch = false;
            if (statusValue == "Alle") statusMatch = true;
            if (item.Status == statusValue) statusMatch = true;
            if (statusValue == "Alle aktive saker" &&
                item.Status != "Utbetalt" &&
                item.Status != "Avvist man." &&
                item.Status != "Kansellert"
            ) statusMatch = true;
            

            if (textMatch && statusMatch) return true;
            return false;
        });
        setFilteredCases(tempfilter);
        setShownCases(tempfilter.slice(0, 15 + showMore));
    }

    function openLoanapplication(newCase: boolean = false) {

        // Plukk og selekter korrekt type finansieringssøknad
        let agreements = auth.selectedDealer?.DealerGroupAgreementConnection || [];
        let agreement = agreements?.find((i: any) => i.agreement.id == selectedCase?.AgreementSequence);

        loanapplication.setID(!newCase ? selectedCase?.ID : undefined);
        loanapplication.setAgreementId(agreement.agreement.id);
        loanapplication.setSequence(!newCase ? selectedCase?.sequence : undefined);
        loanapplication.setStatus(!newCase ? selectedCase?.Status : undefined);
        loanapplication.setCalculationResult(!newCase ? selectedCase?.Calculation?.[0] : undefined);
        loanapplication.setCaseNotes(selectedCase?.CaseNotes);
        loanapplication.setContractlengthmonths(selectedCase?.Calculation?.[0]?.Duration);
        loanapplication.setDueday(selectedCase?.Calculation?.[0]?.DueDay);
        loanapplication.setPurchaseSum(selectedCase?.CaseObjects?.[0]?.Price);
        loanapplication.setDownPaymentSum(selectedCase?.Calculation?.[0]?.DownPayment);
        loanapplication.setStartRentExclMva(selectedCase?.Calculation?.[0]?.DownPayment);
        loanapplication.setStartRentInclMva(selectedCase?.Calculation?.[0]?.DownPayment != undefined ? (selectedCase?.Calculation?.[0]?.DownPayment * 1.25) : undefined);
        loanapplication.setStartRentInclMvaPct((selectedCase?.Calculation?.[0]?.DownPayment != undefined ? (selectedCase?.Calculation?.[0]?.DownPayment * 1.25) : 0) / (selectedCase?.CaseObjects?.[0]?.Price || 1) * 100);
        loanapplication.setPurchaseSumExclMva(selectedCase?.CaseObjects?.[0]?.Price != undefined ? selectedCase?.CaseObjects?.[0]?.Price * 0.8 : undefined);
        loanapplication.setDownPaymentPercentage(Number(Number(selectedCase?.CaseObjects?.[0]?.Price ? (Number(selectedCase?.Calculation?.[0]?.DownPayment) / selectedCase?.CaseObjects?.[0]?.Price) * 100 : 0).toFixed(2)));
        if (selectedCase?.sequence) {
            // Innsendt søknad
            loanapplication.setDownPaymentSource(Number(selectedCase?.CaseVariables?.find(i => i.PreviewCode == general.getMidlenesOpprinnelseCode())?.AnswerValue) || undefined);
        } else {
            // Kladd
            loanapplication.setDownPaymentSource(selectedCase?.CaseVariableCode == general.getMidlenesOpprinnelseCode() ? selectedCase?.CaseVariableValue : undefined);
        }        
        loanapplication.setCaseObjectNew(selectedCase?.CaseObjects?.[0].IsNew);
        loanapplication.setNominalInterestRate(selectedCase?.Calculation?.[0]?.Interest);
        loanapplication.setExactCar((
            (
                (selectedCase?.CaseObjects?.[0]?.RegistrationNumber || null) != null ||
                (selectedCase?.FinanceCaseExtension?.[0]?.ChassisNumber || null) != null
            )
        ));
        loanapplication.setOnetimeFee(selectedCase?.CaseObjects?.[0]?.RegistryFee);
        loanapplication.setRegnrknown((selectedCase?.CaseObjects?.[0]?.RegistrationNumber ?? null) != null);
        loanapplication.setRegnumber(selectedCase?.CaseObjects?.[0]?.RegistrationNumber);
        loanapplication.setRegistrationDate(selectedCase?.CaseObjects?.[0]?.RegistrationDate);
        loanapplication.setInvoiceAddress(selectedCase?.InvoiceAddressSequence);
        loanapplication.setRemainingValue(selectedCase?.Calculation?.[0]?.ResidualValue);        
        let resValuePercent: string = (Number(selectedCase?.Calculation?.[0]?.ResidualValue || 0) / Number(selectedCase?.CaseObjects?.[0]?.Price || 1) * 100.0 + Number.EPSILON).toFixed(2);
        loanapplication.setRemainingValuePct(Number(resValuePercent));

        loanapplication.setObjectSequence(selectedCase?.CaseObjects?.[0]?.Sequence);
        loanapplication.setObjectID(selectedCase?.CaseObjects?.[0]?.ID);
        loanapplication.setObjectmakeName(selectedCase?.CaseObjects?.[0]?.MakeName || undefined);
        loanapplication.setObjectmodelName(selectedCase?.CaseObjects?.[0]?.ModelName || undefined);
        loanapplication.setObjecttypeName(selectedCase?.CaseObjects?.[0]?.TypeName || undefined);
        loanapplication.setObjectmake(selectedCase?.FinanceCaseExtension?.[0]?.CaseObjectMakeID || undefined);
        loanapplication.setObjectmodel(selectedCase?.FinanceCaseExtension?.[0]?.CaseObjectModelID || undefined);
        loanapplication.setChassisnumber(selectedCase?.FinanceCaseExtension?.[0]?.ChassisNumber || undefined);
        loanapplication.setObjectyear(selectedCase?.CaseObjects?.[0]?.ModelYear || undefined);
        loanapplication.setUseableKm(selectedCase?.CaseObjects?.[0]?.AgreedMileage || undefined);
        loanapplication.setMileage(selectedCase?.CaseObjects?.[0]?.Mileage || undefined);
        loanapplication.setDeliveryDate(general.dateToDateString(selectedCase?.CaseObjects?.[0]?.DeliveryDate) || undefined);
        if (general.showArea("prisOverkjortKmEksMva", auth.selectedAgreement, auth.selectedDealer, loanapplication)) {
            loanapplication.setPriceExtraKmExclVAT(selectedCase?.CaseObjects?.[0]?.MileagePrice || undefined);
        }
        if (general.showArea("prisOverkjortKmInklMva", auth.selectedAgreement, auth.selectedDealer, loanapplication)) {
            loanapplication.setPriceExtraKmInclVAT(selectedCase?.CaseObjects?.[0]?.MileagePrice ? selectedCase?.CaseObjects?.[0]?.MileagePrice * 1.25 : undefined);
        }
        
        loanapplication.setCustomerSequence(selectedCase?.CustomerSequence);
        loanapplication.setCustomerSector(selectedCase?.CustomerSector);
        loanapplication.setCustomerEmail(selectedCase?.CustomerEmail);
        loanapplication.setCustomerPhone(selectedCase?.CustomerMobile);
        loanapplication.setCustomerCoborrower(selectedCase?.CoBuyerSequence != undefined);
        loanapplication.setCoCustomerSequence(selectedCase?.CoBuyerSequence);
        loanapplication.setCoCustomerSector(selectedCase?.CoBuyerSector);

        loanapplication.setRequestingInsurance(selectedCase?.FinanceCaseExtension?.[0]?.InsuranceIsRequested);
        loanapplication.setInsuranceCompany(selectedCase?.FinanceCaseExtension?.[0]?.InsuranceCompanyRequested);
        loanapplication.setInsuranceBonus(selectedCase?.FinanceCaseExtension?.[0]?.InsuranceExistingBonus);
        loanapplication.setInsuranceLowAge(selectedCase?.FinanceCaseExtension?.[0]?.InsuranceForPersonsUnder23);
        loanapplication.setInsuranceAnnualKilometers(selectedCase?.FinanceCaseExtension?.[0]?.InsuranceRequestedMileage);
        loanapplication.setInsuranceCoverageTypeCode(selectedCase?.FinanceCaseExtension?.[0]?.InsuranceCoverageTypeCode);
        loanapplication.setInsuranceDeductibleCode(selectedCase?.FinanceCaseExtension?.[0]?.InsuranceDeductibleCode);
        loanapplication.setInsuranceBonusSource(selectedCase?.FinanceCaseExtension?.[0]?.InsuranceBonusSource);
        loanapplication.setInsuranceBonusSourceRegnr(selectedCase?.FinanceCaseExtension?.[0]?.InsuranceBonusSourceRegnr);
        loanapplication.setLoanLeasingCompany(selectedCase?.FinanceCaseExtension?.[0]?.LoanLeasingCompany);

        loanapplication.setComment(selectedCase?.CaseNotes?.[0]?.Text);
        loanapplication.setSendToEsign(/.*Kan sendes rett til e-signering etter behandling.*/.test(selectedCase?.CaseNotes?.[0]?.Text ?? ""))
        loanapplication.setSalesPersonSequence(!newCase ? selectedCase?.SalesPersonSequence : undefined);

        loanapplication.setType(selectedCase?.Type);
        loanapplication.setSegment(selectedCase?.FinanceCaseExtension?.[0]?.SchemaCustomerType);
        loanapplication.setCategory(idCategoryToStrings(selectedCase?.FinanceCaseExtension?.[0]?.SchemaObjectVariant ?? ""));
        loanapplication.setVariant(selectedCase?.FinanceCaseExtension?.[0]?.SchemaAgreementVariant);

        loanapplication.setUpdateDate(!newCase ? selectedCase?.UpdateDate : undefined);
        loanapplication.setCustomerVariables(selectedCase?.CaseVariables?.map((i: any) => {
            return {
                AnswerValue: i.AnswerValue, 
                TypeSequence: i.TypeSequence, 
                TypeCode: i.TypeCode
            }
        }));

        loanapplication.setObjectSupplierSequence(selectedCase?.CaseObjects?.[0]?.SupplierSequence);
        loanapplication.setObjectSupplierContact(selectedCase?.FinanceCaseExtension?.[0]?.ObjectSupplierContact || "")
        loanapplication.setObjectSupplierEmail(selectedCase?.FinanceCaseExtension?.[0]?.ObjectSupplierEmail || "")
        loanapplication.setObjectSupplierPhone(selectedCase?.FinanceCaseExtension?.[0]?.ObjectSupplierPhone || "")
    }

    function uploadFileToCase(casesequence: string, documentdataname: string, documentdefinitioncode: number, documentdatastring: string) {
        toast.info("Laster opp fil ...", { autoClose: false });
        axios.single({
            url: "/api/casedocuments/", 
            method: "POST",
            headers: {
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
            },
            data: {
                "DocumentDataName": documentdataname, 
                "DocumentDataString": documentdatastring,
                "FinanceCaseSequence": casesequence,
                "Document":
                    {
                        "DocumentDefinitionCode" : documentdefinitioncode.toString()
                    }
            }
        }).then((res: any) => {
            toast.dismiss();
            if(res.status !== 200) {
                toast.error("Opplasting av fil feilet! Vennligst prøv igjen");
                return;
            }
            toast.success("Opplasting fullført");
            fetchSingleCaseDocuments(casesequence);
        }).catch((err: any) => {
            console.log(err);
            toast.dismiss();
            toast.error("Opplasting av fil feilet! Vennligst prøv igjen");
        });
    }
    
    function downloadFileBysequence(documentsequence: number) {
        axios.single({
            url: "api/casedocuments/bydocumentid/" + documentsequence,
            method: "GET",
            headers: {
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
            },
        }).then((res: any) => {
            const linkSource = `data:application/octet-stream;base64,${res.data.DocumentDataString}`;
            const downloadLink = document.createElement("a");
            const fileName = res.data.DocumentDataName;
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        }).catch((err: any) => {
            console.log(err);
        });
    }

    function saveCaseToFremtind(caseItem: Case | undefined) {
        if (!caseItem) return;
        if (!caseItem.sequence && !caseItem.ID) return;
        toast.info("Sender sak ...", { autoClose: false });
        axios.single({
            url: "/api/cases/" + (caseItem.sequence || caseItem.ID),
            method: "PUT",
            data: {
                RetryFremtind: true,
                DealerSequence: auth.selectedDealer?.Sequence,
                AgreementSequence: selectedCase?.AgreementSequence,
                CustomerSequence: selectedCase?.CustomerSequence,
                CoBuyerSequence: selectedCase?.CoBuyerSequence,
                "Calculation": [ ],
                "CaseObjects": [ ],
            },
            headers: {
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
            }
        }).then((res: any) => {
            toast.dismiss();
            if (res.status !== 200) {
                let userMessage = general.translateInsuranceErrorMessage({ errorMessage: res.data });
                toast.error(userMessage);
                return;
            }

            fetchSingleCase(caseItem.sequence || caseItem.ID || 0);
            fetchCases();
        }).catch((err: any) => {
            console.log(err);
            toast.dismiss();
            toast.error("Sending av sak feilet!");
        });
    }

    function getFremtindErrors(caseItem: Case | undefined): string[] {
        let errors = [];
        
        if (caseItem?.InsuranceCases == undefined) return [];

        if (caseItem?.InsuranceCases?.length > 0) {
            if (caseItem?.InsuranceCases?.[0]?.Status == "mottatt") {
                errors.push("notsaved");
            }
        }
        
        return errors;
    }

    useEffect(() => {
        filterCases();
    }, [searchValue, statusValue, allCases]);

    useEffect(() => {
        if (showMore != 0) {
            setShownCases(filteredCases.slice(0, 15 + showMore));
        }
    }, [showMore]);

    useEffect(() => {
        if (auth.selectedDealer?.Sequence != undefined) {
            fetchCases();
        }
    }, [showAllCases, auth.selectedDealer?.Sequence]);

    useEffect(() => {
        if (loanapplication?.agreementId != undefined) {
            navigate("/loanapplication");
        }
    }, [loanapplication]);

    useEffect(() => {
        fetchInsuranceCompanies();
        fetchInsuranceCompaniesFremtind();
        fetchInsuranceBonus();
        let caseId = searchParams?.get("caseid");
        if (caseId != undefined) {
            setActiveItem(caseId as any);
            fetchSingleCase(caseId as any);
            if (window.innerWidth < 768) {
                setArchiveOpen(false);
            }
        }
    }, []);

    useEffect(() => {
        setSelectedCase(null);
    }, []);

    return (
        <div className={styles.loanoverview}>
            <Grid className={styles.grid + " " + "noprint"}>
                <GridRow>
                    <GridCol lg="6" md="12" sm="12">
                        <ContentCard
                            open={archiveOpen}
                            onClick={(val) => setArchiveOpen(val)}
                            title="Søknadsoversikt"
                        >
                            <div className={styles.tabgroup}>
                                <TabGroup>
                                    <Tab
                                        selected={!showAllCases}
                                        onClick={() => {
                                            setShowAllCases(false);
                                            setShowMore(1);
                                        }}
                                        aria-controls={"mycases"}
                                    >
                                        Mine saker
                                    </Tab>
                                    <Tab
                                        selected={showAllCases}
                                        onClick={() => {
                                            setShowAllCases(true);
                                            setShowMore(1);
                                        }}
                                        aria-controls={"allcases"}
                                    >
                                        Alle saker
                                    </Tab>
                                </TabGroup>
                            </div>
                            &nbsp;
                            <SearchField
                                value={searchValue}
                                onClick={(value) => {
                                    fetchCases();
                                }}
                                onEnter={(value) => {
                                    setSearchValue(value);
                                }}
                                onChange={(value) => {
                                    setSearchValue(value);
                                }}
                                placeholder="Søkefelt"
                            ></SearchField>
                            <InputGroup
                                label="Søknadsstatus"
                                //fieldMessage={showErrors ? 'Du må velge måned' : null}
                            >
                                <Dropdown
                                    value={statusValue}
                                    onChange={(e) => {
                                        setStatusValue(e.target.value);
                                    }}
                                >
                                    <option value="Alle aktive saker">Alle aktive saker</option>
                                    <option value="Alle">Alle</option>
                                    <option value="Kladd">Kladd</option>
                                    <option value="Godkjent">Godkjent</option>
                                    <option value="Dokumenter sendt">Dokumenter sendt</option>
                                    <option value="Dokumenter signert">Dokumenter signert</option>
                                    <option value="Kontroll">Kontroll</option>
                                    <option value="Utbetalt">Utbetalt</option>
                                    <option value="Avvist man.">Avvist man.</option>
                                </Dropdown>
                            </InputGroup>
                            &nbsp;
                            <div className={styles.loanitemslist}>
                                <hr/>
                                { loading ? 
                                    <Spinner large={true} />
                                : null }
                                { shownCases.map((item, key) => {
                                    return <div key={key}>
                                        <ArchiveItem
                                            className={((activeItem == item.sequence && item.sequence != undefined) || (activeItem == item.ID && item.ID != undefined) ? styles.activeloanitem : '') + " " + (getFremtindErrors(item).length > 0 ? styles.errorloanitem : "")}
                                            title={item.CustomerName == undefined ? "Påbegynt sak" : item.CustomerName || ""}
                                            subtitle1={((item.CaseObjects?.[0]?.MakeName || "") + " " + (item.CaseObjects?.[0]?.ModelName || ""))}
                                            subtitle2={
                                                item.CaseObjects?.[0]?.RegistrationNumber || ""
                                            }
                                            id={item.sequence?.toString()}
                                            pills={item.Status != undefined ?
                                                [
                                                    item?.Status ?
                                                        <Pill
                                                            key={"status"}
                                                            text={item?.Status || ""}
                                                        ></Pill>
                                                    : undefined,
                                                    isAtFinans(item || undefined) ?
                                                        <Pill
                                                            key={"underbehandling"}
                                                            text={"Under behandling"}
                                                        ></Pill>
                                                    : undefined,
                                                    isAtKunde(item || undefined) ? // This case will not work until IsSentToEsign works in case list
                                                        <Pill
                                                            key={"hoskunde"}
                                                            text={"Hos kunde"}
                                                        ></Pill>
                                                    : undefined,
                                                ].filter(i => i != undefined) as Array<React.ReactElement>
                                            : [] }
                                            date={general.dateToDateTimeString(item.UpdateDate) || ""}
                                            locked={item.IsLockedForFront}
                                            onClick={() => {
                                                setActiveItem(item.sequence || item.ID || 0);
                                                // Smooth scroll to top
                                                window.scrollTo({
                                                    top: 0,
                                                    behavior: 'smooth'
                                                });
                                                if (item.sequence != undefined || item.ID != undefined) {
                                                    setSelectedCase(null);
                                                    fetchSingleCase(item.sequence || item.ID);
                                                }
                                                if (window.innerWidth < 768) {
                                                    setArchiveOpen(false);
                                                }
                                                setSearchParams(new URLSearchParams({caseid: (item.sequence || item.ID).toString()}));
                                            }}
                                        ></ArchiveItem>
                                        <hr/>
                                    </div>;
                                })}
                                { shownCases.length == 0 && !loading ?
                                    <p
                                        className={styles.nocases}
                                    >
                                        Ingen saker funnet
                                    </p>
                                : null }
                                { shownCases.length < filteredCases.length ?
                                    <p
                                        className={styles.showmore}
                                        onClick={() => {
                                            setShowMore(showMore + 15);
                                        }}
                                    >Vis flere ..</p>
                                : null }
                            </div>
                        </ContentCard>
                    </GridCol>
                    <GridCol lg="6" md="12" sm="12" className={loadingSingle ? styles.loadingsingle : ""}>
                        <ContentCard open={selectedCase != null} disabled={selectedCase == null} title="Oppsummering">
                            <>
                                { getFremtindErrors(selectedCase ?? undefined).length > 0 ?
                                    <>
                                        { getFremtindErrors(selectedCase ?? undefined).map((item, key) => {
                                            switch (item) {
                                                case "notsaved":
                                                    return <p key={key} style={{ color: "red" }}>FEIL: Forsikringen ble ikke sendt til Fremtind</p>;
                                                default:
                                                    return <p key={key} style={{ color: "red" }}>FEIL: {item}</p>;
                                            }
                                        }) }
                                        <ActionButton
                                            onClick={() => {
                                                saveCaseToFremtind(selectedCase ?? undefined);
                                            }}
                                        >{
                                            getFremtindErrors(selectedCase ?? undefined)[0] == "notsaved" ? "Send på nytt" : ""
                                        }</ActionButton>
                                        &nbsp;
                                        <hr/>
                                        &nbsp;
                                    </>
                                : null }
                                <div className={styles.loanactions}>
                                    <SplitButton
                                        disabled={
                                            !(
                                                (
                                                    selectedCase?.IsReady == false &&
                                                    selectedCase?.Incoming == true &&
                                                    (
                                                        selectedCase?.Status == "Kontroll" ||
                                                        selectedCase?.Status == "Godkjent"
                                                    )
                                                ) || selectedCase?.sequence == null
                                            )
                                        }
                                        className={styles.editloanbutton}
                                        buttontext={"Rediger"}
                                        onClick={() => {
                                            openLoanapplication();
                                        }}
                                        altOptions={[
                                            selectedCase?.Status == "Godkjent" && 
                                            selectedCase?.Incoming == true && 
                                            selectedCase?.IsReady === false ? {
                                                text: "Overfør til finans",
                                                onClick: () => {
                                                    setCaseReady(selectedCase?.sequence || undefined, selectedCase?.ID || undefined, true);
                                                }
                                            } : undefined,

                                            (
                                                selectedCase?.Status == "Godkjent" ||
                                                selectedCase?.Status == "Kontroll"
                                            ) && 
                                            selectedCase?.IsReady === true ? {
                                                text: "Ta tilbake sak",
                                                onClick: () => {
                                                    setCaseReady(selectedCase?.sequence || undefined, selectedCase?.ID || undefined, false);
                                                }
                                            } : undefined,

                                            (
                                                selectedCase?.Status == "Kontroll" ||
                                                selectedCase?.Status == "Godkjent" ||
                                                selectedCase?.Status == "Dokumenter sendt" ||
                                                selectedCase?.Status == "Dokumenter signert" ||
                                                selectedCase?.Status == "Klar for utbetaling" ||
                                                selectedCase?.Status == "Utbetalt" ||
                                                selectedCase?.StatusRaw == "NewCase"
                                            ) ?
                                            {
                                                text: "Kopier",
                                                onClick: () => {
                                                    openLoanapplication(true);
                                                }
                                            } : undefined,

                                            (
                                                selectedCase?.sequence == null ||
                                                selectedCase?.Status == "Kontroll" ||
                                                selectedCase?.Status == "Godkjent" ||
                                                selectedCase?.Status == "Dokumenter sendt" ||
                                                selectedCase?.StatusRaw == "NewCase"
                                            ) ? {
                                                text: selectedCase?.sequence == null ? "Slett kladd" : "Kanseller",
                                                onClick: () => {
                                                    if (selectedCase?.Status == "Kladd" ? window.confirm("Er du sikker på at du vil slette denne kladden?") : window.confirm("Er du sikker på at du vil kansellere denne saken?")) {
                                                        cancelCase(selectedCase?.sequence || undefined, selectedCase?.ID || undefined);
                                                    }
                                                }
                                            } : undefined,
                                        ]}
                                    ></SplitButton>
                                </div>
                                <div className={styles.loaninfoarea}>
                                    <h2>{selectedCase?.Type == "Loan" ? "Lån" : "Leasing"}</h2>
                                    <p><span>Saksnummer:</span><span>{selectedCase?.sequence || "Kladd"}</span></p>
                                    <p>
                                        <span>Terminbeløp:</span>
                                        <span>
                                            {selectedCase?.Calculation?.[0].TotalPeriodicAmount
                                            ? general.formatPrice(
                                                (selectedCase?.Calculation?.[0].TotalPeriodicAmount +
                                                    (selectedCase?.Calculation?.[0].TotalPeriodicAmountVat ?? 0)),
                                                false,
                                                true
                                                )
                                            : "N/A"}
                                        </span>
                                    </p>
                                    <p><span>Nominell rente:</span><span>{ `${selectedCase?.Calculation?.[0].Interest ?? ''}%` }</span></p>
                                    <p><span>Løpetid (år):</span><span>{selectedCase?.Calculation?.[0].DurationInYears ?? ''}</span></p>
                                    {selectedCase?.Type == "Loan" &&
                                        <>
                                            <p><span>Kjøpesum:</span><span>{selectedCase?.CaseObjects?.[0]?.Price ? general.formatPrice(selectedCase.CaseObjects[0].Price, false, true) : "0,- kr"}</span></p>
                                            <p><span>Egenkapital:</span><span>{selectedCase?.Calculation?.[0]?.DownPayment ? general.formatPrice((selectedCase.Calculation[0].DownPayment), false, true) : "0,- kr"}</span></p>
                                        </>
                                    }
                                    &nbsp;
                                    {/* { selectedCase?.CaseVariables?.find(i => i.PreviewCode == 958) != undefined ?
                                        <div className={styles.warnings}>
                                            <p>{selectedCase?.CaseVariables?.find(i => i.PreviewCode == 958)?.AnswerValue}</p>
                                        </div>
                                    : null } */}
                                </div>
                                <hr/>
                                <div className={styles.esigningbutton}>
                                    { selectedCase?.CustomerSector != "8500" && selectedCase?.CustomerSector != "8200" ?
                                        <SplitButton
                                            disabled={
                                                !(
                                                    selectedCase?.Incoming &&
                                                    selectedCase?.Status == "Godkjent"
                                                )
                                            }
                                            buttontext={"Print dok"}
                                            onClick={() => {
                                                getCaseSigningDocuments(selectedCase?.sequence || undefined, selectedCase?.ID || undefined);
                                            }}
                                            altOptions={[]}
                                        ></SplitButton>
                                    : selectedCase?.Status != "Utbetalt" ? 
                                        <SplitButton
                                            disabled={
                                                !(
                                                    !isCompany(selectedCase) &&
                                                    selectedCase?.Incoming == true &&
                                                    selectedCase?.Status == "Godkjent" &&
                                                    (
                                                        selectedCase?.CaseObjects?.[0]?.RegistrationNumber != undefined &&
                                                        selectedCase?.CaseObjects?.[0]?.RegistrationNumber != null &&
                                                        selectedCase?.CaseObjects?.[0]?.RegistrationNumber != ""
                                                    )
                                                )
                                            }
                                            buttontext={"E-Sign"}
                                            onClick={() => {
                                                let confirm = window.confirm("Saken vil overføres til Finans og bli låst for redigering. Er du sikker på at du vil sende saken til signering?");
                                                if (confirm) {
                                                    sendCaseToSigning(selectedCase?.sequence || undefined, selectedCase?.ID || undefined);
                                                }
                                            }}
                                            altOptions={ [
                                                (
                                                    !isCompany(selectedCase) &&
                                                    selectedCase?.Status == "Dokumenter sendt" &&
                                                    selectedCase?.IsSentToESign
                                                ) ?
                                                    {
                                                        text: "Vis E-link",
                                                        onClick: () => {
                                                            getCaseSigningLink(selectedCase?.sequence || undefined, selectedCase?.ID || undefined);
                                                        }
                                                    }
                                                : undefined,

                                                (
                                                    isCompany(selectedCase) ?
                                                        (
                                                            selectedCase?.IsReady == false &&
                                                            selectedCase?.Incoming == true &&
                                                            selectedCase?.Status == "Godkjent" &&
                                                            documentList.length == 0
                                                        )
                                                    :
                                                        (
                                                            selectedCase?.Incoming == true &&
                                                            selectedCase?.Status == "Godkjent"
                                                        )
                                                ) ?
                                                    {
                                                        text: "Print dok",
                                                        onClick: () => {
                                                            getCaseSigningDocuments(selectedCase?.sequence || undefined, selectedCase?.ID || undefined);
                                                        }
                                                    }
                                                : undefined,
                                            ].filter((item) => item !== undefined) }
                                        ></SplitButton>
                                    : null}
                                </div>
                                <div className={styles.loancaseinfoarea}>
                                    <p className={styles.loanstatus}>Status: {selectedCase?.Status}</p>
                                    <p className={styles.loantitle}>Kundens navn: {selectedCase?.caseCustomer?.Name || selectedCase?.CustomerName || ""}</p>
                                    <p>{selectedCase?.CaseObjects?.[0]?.MakeName} {selectedCase?.CaseObjects?.[0]?.ModelName}</p>
                                    <p>{selectedCase?.CaseObjects?.[0]?.RegistrationNumber ?? selectedCase?.CaseObjects?.[0]?.ChassisNumber}</p>
                                    <div className={styles.loanpills}>
                                        { selectedCase?.Status ?
                                            <Pill
                                                key={"status"}
                                                text={selectedCase?.Status || ""}
                                            ></Pill>
                                        : null }
                                        { isAtFinans(selectedCase || undefined) ?
                                            <Pill
                                                key={"underbehandling"}
                                                text={"Under behandling"}
                                            ></Pill>
                                        : null }
                                        { isAtKunde(selectedCase || undefined) ?
                                            <Pill
                                                key={"hoskunde"}
                                                text={"Hos kunde"}
                                            ></Pill>
                                        : null }
                                    </div>
                                    <div className={styles.warnings}>
                                        { isAtKunde(selectedCase || undefined) ?
                                            <>
                                                <span className="material-symbols-outlined" style={{float: "left"}}>
                                                    info
                                                </span>
                                                <p>Saken avventer tilbakemelding fra kunde</p>
                                            </>
                                        : null }
                                        { isAtFinans(selectedCase || undefined) ?
                                            <>
                                                <span className="material-symbols-outlined" style={{float: "left"}}>
                                                    info
                                                </span>
                                                <p>Saken er til behandling hos finans</p>
                                            </>
                                        : null }
                                    </div>
                                    {selectedCase?.CaseVariables?.find(i => i.PreviewCode == 957) != undefined || selectedCase?.CaseVariables?.find(i => i.PreviewCode == 958) != undefined ?
                                        <div className={styles.detailedInformation}>
                                            <p><span>{selectedCase?.CaseVariables?.find(i => i.PreviewCode == 957)?.PreviewName != undefined ? (selectedCase?.CaseVariables?.find(i => i.PreviewCode == 957)?.PreviewName + ": ") : ""}</span> {selectedCase?.CaseVariables?.find(i => i.PreviewCode == 958)?.AnswerValue ?? ""}</p>
                                        </div>
                                    : null }
                                </div>
                                { selectedCase?.InsuranceCases?.[0]?.Status == "tilbud" && !insuranceActivated ?
                                    <>
                                        <hr/>
                                        <Accordion
                                            headingLevel={2}
                                            size={2}
                                        >
                                            <AccordionItem
                                                heading={<span className={styles.collapsetitle}>Forsikringsaktivering</span>}
                                            >
                                                <div className={styles.insuranceactivation}>
                                                    { Number(selectedCase?.FinanceCaseExtension?.[0]?.InsuranceExistingBonus || 0) > 60 ?
                                                        <>
                                                            <InputGroup
                                                                extraMargin={false}
                                                                label="Hvilket forsikringsselskap har du bonusen hos?"
                                                                // tooltip="Hva er kundens nåværende forsikringsselskap med bonusen?"
                                                            >
                                                                <Dropdown
                                                                    value={selectedCaseInsuranceBonusSource != "" ? selectedCaseInsuranceBonusSource : "choose"}
                                                                    onBlur={() => {
                                                                        let tempSelectedCase = JSON.parse(JSON.stringify(selectedCase));
                                                                        setSelectedCase({
                                                                            ...tempSelectedCase,
                                                                            FinanceCaseExtension: [{
                                                                                ...tempSelectedCase?.FinanceCaseExtension?.[0],
                                                                                InsuranceBonusSource: selectedCaseInsuranceBonusSource
                                                                            }]
                                                                        });
                                                                    }}
                                                                    onChange={e => {
                                                                        let value: string | undefined = e.target.value;
                                                                        if(value.trim().length == 0){
                                                                            value = undefined;
                                                                        }
                                                                        setSelectedCaseInsuranceBonusSource(value);
                                                                    }}
                                                                >
                                                                    <option value="choose" disabled>
                                                                        Velg selskap
                                                                    </option>
                                                                    { insuranceCompaniesFremtind?.map((option: any, index: any) =>
                                                                        <option key={index} value={option.value}>
                                                                            {option.label}
                                                                        </option>
                                                                    )}
                                                                </Dropdown>
                                                            </InputGroup>
                                                            <InputGroup
                                                                label="Regnummer på objektet som har bonusen"
                                                                // tooltip="Registreringsnummer på objektet med bonusen"
                                                            >
                                                                {inputProps => (
                                                                    <Input
                                                                        type="text"
                                                                        name="regnumber"
                                                                        value={selectedCaseInsuranceBonusSourceRegnr || ""}
                                                                        onBlur={ () => {
                                                                            let tempSelectedCase = JSON.parse(JSON.stringify(selectedCase));
                                                                            setSelectedCase({
                                                                                ...tempSelectedCase,
                                                                                FinanceCaseExtension: [{
                                                                                    ...tempSelectedCase?.FinanceCaseExtension?.[0],
                                                                                    InsuranceBonusSourceRegnr: formatRegistrationNumber(selectedCaseInsuranceBonusSourceRegnr || "")
                                                                                }]
                                                                            })
                                                                        }}
                                                                        onChange={e => {
                                                                            let value: string | undefined = e.target.value;
                                                                            if(value.trim().length == 0){
                                                                                value = undefined;
                                                                            }
                                                                            setSelectedCaseInsuranceBonusSourceRegnr(value);
                                                                        }}
                                                                        {...inputProps}
                                                                    />
                                                                )}
                                                            </InputGroup>
                                                            &nbsp;
                                                        </>
                                                    : null }
                                                    {   selectedCase?.CaseObjects?.[0]?.RegistrationNumber == undefined && (
                                                            selectedCase?.FinanceCaseExtension?.[0]?.ChassisNumber == undefined ||
                                                            selectedCase?.FinanceCaseExtension?.[0]?.ChassisNumber == "" ||
                                                            (selectedCase?.FinanceCaseExtension?.[0]?.ChassisNumber ?? "").length > 9
                                                        ) ?
                                                            <p style={{color: "red"}}>Du må legge til regnummer eller chassisnummer på det nye objektet for å kunne aktivere forsikring</p>
                                                    : null }
                                                    { selectedCase?.CaseObjects?.[0]?.RegistrationNumber == undefined || selectedCase?.CaseObjects?.[0]?.RegistrationNumber == "" ?
                                                        <InputGroup
                                                            label="Regnummer"
                                                            // tooltip="Registreringsnummer på objektet med bonusen"
                                                        >
                                                            {inputProps => (
                                                                <Input
                                                                    type="text"
                                                                    name="regnumber"
                                                                    value={selectedCaseRegnr || ""}
                                                                    onChange={e => {
                                                                        let value: string | undefined = e.target.value;
                                                                        if(value.trim().length == 0){
                                                                            value = undefined;
                                                                        }
                                                                        setSelectedCaseRegnr(value);
                                                                    }}
                                                                    {...inputProps}
                                                                />
                                                            )}
                                                        </InputGroup>
                                                    : null}
                                                    { selectedCase?.FinanceCaseExtension?.[0]?.ChassisNumber == undefined || selectedCase?.FinanceCaseExtension?.[0]?.ChassisNumber == "" ?
                                                        <InputGroup
                                                            label="Chassisnummer"
                                                            tooltip="Chassisnummer på finansieringsobjektet. Minimum 9 tegn. Kreves for bestilling av forsikring uten regnummer."
                                                            fieldMessage={
                                                                selectedCaseChassisNumber != undefined &&
                                                                selectedCaseChassisNumber != "" &&
                                                                selectedCaseChassisNumber.length > 9
                                                                    ? "Ugyldig chassisnummer. Må være minimum 9 tegn."
                                                                    : null
                                                            }
                                                        >
                                                            {inputProps => (
                                                                <Input
                                                                    type="text"
                                                                    name="chassisnumber"
                                                                    value={selectedCaseChassisNumber || ""}
                                                                    onChange={e => {
                                                                        let value: string | undefined = e.target.value;
                                                                        if(value.trim().length == 0){
                                                                            value = undefined;
                                                                        }
                                                                        setSelectedCaseChassisNumber(value);
                                                                    }}
                                                                    {...inputProps}
                                                                />
                                                            )}
                                                        </InputGroup>
                                                    : null }
                                                    <hr/>
                                                    <ActionButton
                                                        disabled={
                                                            activateInsuranceLoading ||
                                                            (
                                                                (
                                                                    selectedCase?.CaseObjects?.[0]?.RegistrationNumber == undefined &&
                                                                    (selectedCaseRegnr == undefined || selectedCaseRegnr == "")
                                                                ) && (
                                                                    (
                                                                        selectedCase?.FinanceCaseExtension?.[0]?.ChassisNumber == undefined ||
                                                                        selectedCase?.FinanceCaseExtension?.[0]?.ChassisNumber == "" ||
                                                                        (selectedCase?.FinanceCaseExtension?.[0]?.ChassisNumber ?? "").length > 9
                                                                    ) && (
                                                                        selectedCaseChassisNumber == undefined ||
                                                                        selectedCaseChassisNumber == ""
                                                                    )
                                                                )
                                                            ) ||
                                                            (
                                                                Number(selectedCase?.FinanceCaseExtension?.[0]?.InsuranceExistingBonus || 0) > 60 && (
                                                                    selectedCase?.FinanceCaseExtension?.[0]?.InsuranceBonusSource == undefined ||
                                                                    selectedCase?.FinanceCaseExtension?.[0]?.InsuranceBonusSource == ""
                                                                )
                                                            ) ||
                                                            (
                                                                Number(selectedCase?.FinanceCaseExtension?.[0]?.InsuranceExistingBonus || 0) > 60 && (
                                                                    selectedCaseInsuranceBonusSourceRegnr == undefined ||
                                                                    selectedCaseInsuranceBonusSourceRegnr == ""
                                                                )
                                                            )
                                                        }
                                                        onClick={() => {
                                                            activateInsurance();
                                                        }}
                                                    >Aktiver forsikring</ActionButton>
                                                </div> 
                                            </AccordionItem>
                                        </Accordion>
                                    </>
                                : null }
                            </>
                        </ContentCard>
                        { (selectedCase?.DocumentTypes?.length ?? 0) > 0 && selectedCase?.Status != "Kladd" ?
                            <ContentCard disabled={selectedCase == null} open={selectedCase != null ? false : undefined} title="Vedlegg"/*/Scoring"*/>
                                <div className={styles.tabgroup}>
                                    <TabGroup>
                                        <Tab
                                            selected={showCaseDetails == "attachments"}
                                            onClick={() => {
                                                setShowCaseDetails("attachments");
                                            }}
                                            aria-controls={"attachments"}
                                        >
                                            Last opp
                                        </Tab>
                                        {/* <Tab
                                            selected={showCaseDetails == "scoring"}
                                            onClick={() => {
                                                setShowCaseDetails("scoring");
                                            }}
                                            aria-controls={"scoring"}
                                        >
                                            Scoring
                                        </Tab> */}
                                    </TabGroup>
                                </div>
                                &nbsp;
                                <InputGroup
                                    extraMargin={false}
                                    label="Last opp dokument"
                                >
                                    <Dropdown
                                        value={documentType == 0 ? "" : documentType.toString()}
                                        onChange={e => {
                                            setDocumentType(Number(e.target.value));
                                        }}
                                    >
                                        <option disabled value={""}>Velg dokumenttype</option>
                                        { selectedCase?.DocumentTypes?.map((documentType: any, key: number) => 
                                            <option key={documentType.Identifier || key} value={documentType.Identifier}>{documentType.Name}</option>
                                        )}
                                    </Dropdown>
                                </InputGroup>
                                &nbsp;
                                <div className={styles.loanattachmentscontent} style={{display: showCaseDetails == "attachments" ? "block" : "none"}}>
                                    { documentType != 0?
                                        <FileUpload
                                            onChange={(file) => {
                                                let reader = new FileReader();
                                                reader.readAsDataURL(file);
                                                reader.onload = function () {
                                                    uploadFileToCase(selectedCase?.sequence || "",file.name,documentType,(reader.result as string).split(",")[1]);
                                                };
                                                reader.onerror = function (error) {
                                                    console.log('Error: ', error);
                                                };
                                            }}
                                        ></FileUpload>
                                    :null }
                                    <hr />
                                    &nbsp;
                                    <FileList
                                        loading={documentListLoading}
                                        documents={documentList}
                                        onDocumentClick={(document) => {
                                            downloadFileBysequence(document.value);
                                        }}
                                    ></FileList>
                                    &nbsp;
                                </div>
                                {/* <div className={styles.loanscoringcontent} style={{display: showCaseDetails == "scoring" ? "block" : "none"}}>
                                    <hr/>
                                    <p>
                                        <span>Kantega beslutning</span>
                                        <span>{selectedCase?.scoring?.kantega}</span>
                                    </p>
                                    <hr/>
                                    <p>
                                        <span>{"Feilmeldinger"}</span>
                                        <span dangerouslySetInnerHTML={{ __html: selectedCase?.scoring?.errors?.join("<br/>") || "" }}></span>
                                    </p>
                                    <hr/>
                                    <p>
                                        <span>Likviditetsindikator</span>
                                        <span>{selectedCase?.scoring?.indicator}</span>
                                    </p>
                                </div> */}
                            </ContentCard>
                        : null }
                        <ContentCard disabled={selectedCase == null} open={selectedCase != null ? false : undefined} title="Finansieringsdetaljer">
                            <div className={styles.loandetails}>
                                { general.getCaseAgreement(auth.selectedDealer, selectedCase)?.agreement?.schema?.fields?.includes("nominellRente") ?
                                    <>
                                        <hr/>
                                        <p>
                                            <span>Nominell rente</span>
                                            <span>{general.formatPercentage(selectedCase?.Calculation?.[0]?.Interest ?? 0)}</span>
                                        </p>
                                    </>
                                : null }
                                { general.getCaseAgreement(auth.selectedDealer, selectedCase)?.agreement?.schema?.fields?.includes("effektivRente") ?
                                    <>
                                        <hr/>
                                        <p>
                                            <span>Effektiv rente</span>
                                            <span>{general.formatPercentage(selectedCase?.Calculation?.[0]?.EffectiveInterest ?? 0)}</span>
                                        </p>
                                    </>
                                : null }
                                { general.getCaseAgreement(auth.selectedDealer, selectedCase)?.agreement?.schema?.fields?.includes("lopetidManed") ?
                                    <>
                                        <hr/>
                                        <p>
                                            <span>Løpetid (mnd)</span>
                                            <span>{selectedCase?.Calculation?.[0]?.Duration} mnd</span>
                                        </p>
                                    </>
                                : null }
                                { (general.getCaseAgreement(auth.selectedDealer, selectedCase)?.agreement?.schema?.fields?.includes("ingenMvaVedBrukt") && !selectedCase?.CaseObjects?.[0]?.IsNew) ||
                                  general.getCaseAgreement(auth.selectedDealer, selectedCase)?.agreement?.schema?.fields?.includes("terminbelop") || 
                                  general.getCaseAgreement(auth.selectedDealer, selectedCase)?.agreement?.schema?.fields?.includes("terminbelopInklMva") ?
                                    <>
                                        <hr/>
                                        <p>
                                            <span>Terminbeløp</span>
                                            <span>{general.formatPrice(
                                                (selectedCase?.Calculation?.[0]?.TotalPeriodicAmount ?? 0) + 
                                                ((general.getCaseAgreement(auth.selectedDealer, selectedCase)?.agreement?.schema?.fields?.includes("ingenMvaVedBrukt") && !selectedCase?.CaseObjects?.[0]?.IsNew) ? 0 : selectedCase?.Calculation?.[0]?.TotalPeriodicAmountVat ?? 0), false, true
                                            ) ?? "N/A"}</span>
                                        </p>
                                    </>
                                : null }
                                { selectedCase?.DebtInsurance?.[0]?.PeriodicInsuranceFee ?
                                    <>
                                        <hr/>
                                        <p>
                                            <span>Herav betalingsforsikring</span>
                                            <span>{general.formatPrice(selectedCase?.DebtInsurance?.[0]?.PeriodicInsuranceFee, false, true)}</span>
                                        </p>
                                    </>
                                : null }
                                { general.getCaseAgreement(auth.selectedDealer, selectedCase)?.agreement?.schema?.fields?.includes("ingenMvaVedBrukt") && 
                                !selectedCase?.["CaseObjects"]?.[0]?.["IsNew"] ? 
                                    null 
                                :  
                                    general.getCaseAgreement(auth.selectedDealer, selectedCase)?.agreement?.schema?.fields?.includes("terminbelopEksMva") || 
                                    general.getCaseAgreement(auth.selectedDealer, selectedCase)?.agreement?.schema?.fields?.includes("terminbelopFritattMva") ?
                                        <>
                                            <hr/>
                                            <p>
                                                <span>Terminbeløp (eks. mva)</span>
                                                <span>{general.formatPrice(Number(selectedCase?.Calculation?.[0]?.TotalPeriodicAmount), false, true)}</span>
                                            </p>
                                        </>
                                    : null 
                                }
                                { general.getCaseAgreement(auth.selectedDealer, selectedCase)?.agreement?.schema?.fields?.includes("beregningsgrunnlag") ?
                                    <>
                                        <hr/>
                                        <p>
                                            <span>Beregningsgrunnlag</span>
                                            <span>{general.formatPrice(Number(selectedCase?.Calculation?.[0]?.FinancedAmount), false, true)}</span>
                                        </p>
                                    </>
                                : null }
                                { general.getCaseAgreement(auth.selectedDealer, selectedCase)?.agreement?.schema?.fields?.includes("termingebyr") ?
                                    <>
                                        <hr/>
                                        <p>
                                            <span>{
                                                "Termingebyr" +
                                                (general.getCaseAgreement(auth.selectedDealer, selectedCase)?.agreement?.schema?.fields?.includes("termingebyrEksMva") ? " (eks. mva)" : "") +
                                                ":"
                                            }</span>
                                            <span>{general.formatPrice(Number(selectedCase?.Calculation?.[0]?.InvoiceFee), false, true)}</span>
                                        </p>
                                    </>
                                : null }
                                { general.getCaseAgreement(auth.selectedDealer, selectedCase)?.agreement?.schema?.fields?.includes("tinglysningsgebyr") ?
                                    <>
                                        <hr/>
                                        <p>
                                            <span>Tinglysningsgebyr</span>
                                            <span>{general.formatPrice(Number(selectedCase?.Calculation?.[0]?.DocumentFee), false, true)}</span>
                                        </p>
                                    </>
                                : null }
                                { general.getCaseAgreement(auth.selectedDealer, selectedCase)?.agreement?.schema?.fields?.includes("etableringskostnad") ?
                                    <>
                                        <hr/>
                                        <p>
                                            <span>Etableringskostnad</span>
                                            <span>{general.formatPrice(Number(selectedCase?.Calculation?.[0]?.AdministrationFee), false, true)}</span>
                                        </p>
                                    </>
                                : null }
                                { selectedCase?.Calculation?.[0]?.Expenses?.map((expense: any, key: any) => {
                                    if (expense.BudgetClassSequence == 5 && 
                                    !general.getCaseAgreement(auth.selectedDealer, selectedCase)?.agreement?.schema?.fields?.includes("korreksjonMvaPrMndPersonbil")) 
                                            return undefined;
                                    return (
                                        <div className={styles.result} key={key}>
                                            <hr/>
                                            <p style={{
                                                paddingRight: "14px"
                                            }}>{general.getBudgetClassName(expense)}</p>
                                            <p>{general.formatPrice(
                                                expense.BudgetClassSequence == 5 ? (expense.Amount * 1.25) : expense.Amount, false, true
                                            ) ?? "N/A"}</p>
                                        </div>
                                    );
                                }).filter((i: any) => i != undefined) ?? null }

                                {/* <p>
                                    <span>Kjøpesum</span>
                                    <span>{general.formatPrice(Number(selectedCase?.CaseObjects?.[0]?.Price), false, true)}</span>
                                </p>
                                <hr/> */}
                                {/* <p>
                                    <span>Egenkapital (inkl. mva)</span>
                                    <span>{general.formatPrice(Number(selectedCase?.Calculation?.[0]?.DownPayment) + Number(selectedCase?.Calculation?.[0]?.DownPaymentVAT), false, true)}</span>
                                </p>
                                <hr/>
                                <p>
                                    <span>Finansieringsbeløp</span>
                                    <span>{general.formatPrice(Number(selectedCase?.Calculation?.[0]?.FinancedAmount), false, true)}</span>
                                </p> */}
                                
                                { general.getCaseAgreement(auth.selectedDealer, selectedCase)?.agreement?.schema?.fields?.includes("printKalkyleMulig") ?
                                    <SecondaryButton
                                        className={styles.printbutton}
                                        //isLoading={calculating}
                                        onClick={() => {setTriggerPrint(triggerPrint + 1);}}
                                        /* disabled={
                                            loanapplication?.calculationResult != undefined &&
                                            !calculating &&
                                            !changesMade &&
                                            loanapplication?.freeinterestcalc != true &&
                                            problems.length == 0 ?
                                                false
                                            :
                                                true
                                        } */
                                    >Skriv ut kalkulering</SecondaryButton>
                                : null}
                                { general.getCaseAgreement(auth.selectedDealer, selectedCase)?.agreement?.schema?.fields?.includes("visUtvidetInfo") ?
                                    <>
                                        <hr/>
                                        <p style={{
                                            cursor: "pointer",
                                            color: "#0072c6",
                                            textAlign: "center"
                                        }} onClick={() => {
                                            setShowExtraInfo(!showExtraInfo);
                                        }}>Vis utvidet info</p>
                                        { showExtraInfo ?
                                            <>
                                                <p className={styles.result}>
                                                    <span>Totalt</span>
                                                    <span>{general.formatPrice(selectedCase?.Calculation?.[0]?.CommissionUpfrontDealer ?? 0, false, true)}</span>
                                                </p>
                                                <p className={styles.totalcost}>
                                                    Total kostnad over {selectedCase?.Calculation?.[0]?.Duration} mnd: {
                                                        general.formatPrice(
                                                            (selectedCase?.Calculation?.[0]?.TotalCreditCost ?? 0) +
                                                            (selectedCase?.Calculation?.[0]?.FinancedAmount ?? 0), false, true
                                                        )
                                                    }
                                                </p>
                                            </>
                                        : null}
                                    </>
                                : null }
                                
                            </div>
                            
                        </ContentCard>
                        <ContentCard disabled={selectedCase == null} open={selectedCase != null ? false : undefined} title="Objekt">
                            <div className={styles.vehicle}>
                                <hr/>
                                <p>
                                    <span>Merke</span>
                                    <span>{selectedCase?.CaseObjects?.[0]?.MakeName}</span>
                                </p>
                                <hr/>
                                <p>
                                    <span>Modell</span>
                                    <span>{selectedCase?.CaseObjects?.[0]?.ModelName}</span>
                                </p>
                                <hr/>
                                <p>
                                    <span>Registeringsnummer</span>
                                    <span>{selectedCase?.CaseObjects?.[0]?.RegistrationNumber}</span>
                                </p>
                                <hr/>
                                <p>
                                    <span>Chassisnummer</span>
                                    <span>{selectedCase?.CaseObjects?.[0]?.ChassisNumber}</span>
                                </p>
                                <hr/>
                                <p>
                                    <span>Årsmodell</span>
                                    <span>{selectedCase?.CaseObjects?.[0]?.ModelYear}</span>
                                </p>
                                <hr/>
                                <p>
                                    <span>Tilstand</span>
                                    <span>{
                                        selectedCase?.CaseObjects?.[0]?.IsNew !== undefined ?
                                            selectedCase?.CaseObjects?.[0]?.IsNew ?
                                                "Ny"
                                            :
                                                "Brukt"
                                        :
                                            ""
                                    }</span>
                                </p>
                                {selectedCase?.CaseObjects?.[0]?.DeliveryDate &&
                                    <>
                                        <hr/>
                                        <p>
                                            <span>Leveringsdato</span>
                                            <span>{selectedCase?.CaseObjects?.[0]?.DeliveryDate}</span>
                                        </p>
                                    </>
                                }
                            </div>
                        </ContentCard>
                        <ContentCard disabled={selectedCase == null} open={selectedCase != null ? false : undefined} title="Forsikring">
                            <div className={styles.insurance}>
                                { selectedCase?.FinanceCaseExtension?.[0]?.InsuranceIsRequested ?
                                    <p>
                                        <span style={{textAlign: "center", width: "100%"}}>Ønsker forsikring</span>
                                    </p>
                                :
                                    <>
                                        <p style={{ textAlign: "center" }}>
                                            <span style={{ float: "none" }}>Har forsikring hos andre</span>
                                        </p>
                                        <hr/>
                                        <p>
                                            <span>Forsikringsselskap</span>
                                            <span>{
                                                insuranceCompanies?.find((company: any) => company.value == selectedCase?.FinanceCaseExtension?.[0]?.InsuranceCompanyRequested)?.label ?? "Ikke funnet"
                                            }</span>
                                        </p>
                                    </>
                                }
                                <hr/>
                                { 
                                    auth.selectedDealer?.insuranceEnabled != undefined &&
                                    auth.selectedDealer?.insuranceEnabled != false && 
                                    selectedCase?.FinanceCaseExtension?.[0]?.InsuranceIsRequested
                                ?
                                    <>
                                        <p>
                                            <span>Bonus</span>
                                            <span>{
                                                insuranceBonusOptions?.find((option: any) => option.value == selectedCase?.FinanceCaseExtension?.[0]?.InsuranceExistingBonus)?.label ?? "Ikke funnet"
                                            }</span>
                                        </p>
                                        { Number(selectedCase?.FinanceCaseExtension?.[0]?.InsuranceExistingBonus ?? 0) > 60 ?
                                            <>
                                                <hr/>
                                                <p>
                                                    <span>Bonus fra selskap</span>
                                                    <span>{
                                                        insuranceCompaniesFremtind?.find((company: any) => company.value == selectedCase?.FinanceCaseExtension?.[0]?.InsuranceBonusSource)?.label ?? "Ikke funnet"
                                                    }</span>
                                                </p>
                                            </>
                                        : null }
                                        <hr/>
                                        <p>
                                            <span>Kjørelengde</span>
                                            <span>{
                                                selectedCase?.FinanceCaseExtension?.[0]?.InsuranceRequestedMileage
                                            } 000</span>
                                        </p>
                                        <hr/>
                                        <p>
                                            <span>Fører under 23 år</span>
                                            <span>{selectedCase?.FinanceCaseExtension?.[0]?.InsuranceForPersonsUnder23 ? "Ja" : "Nei"}</span>
                                        </p>
                                        
                                        { selectedCase?.InsuranceCases?.[0]?.Status == "tilbud" && !insuranceActivated ?
                                            <>
                                                <hr/>
                                                <Accordion
                                                    headingLevel={2}
                                                    size={2}
                                                >
                                                    <AccordionItem
                                                        heading={<span className={styles.collapsetitle}>Forsikringsaktivering</span>}
                                                    >
                                                        <div className={styles.insuranceactivation}>
                                                            { Number(selectedCase?.FinanceCaseExtension?.[0]?.InsuranceExistingBonus || 0) > 60 ?
                                                                <>
                                                                    <InputGroup
                                                                        extraMargin={false}
                                                                        label="Hvilket forsikringsselskap har du bonusen hos?"
                                                                        // tooltip="Hva er kundens nåværende forsikringsselskap med bonusen?"
                                                                    >
                                                                        <Dropdown
                                                                            value={selectedCaseInsuranceBonusSource != "" ? selectedCaseInsuranceBonusSource : "choose"}
                                                                            onBlur={() => {
                                                                                let tempSelectedCase = JSON.parse(JSON.stringify(selectedCase));
                                                                                setSelectedCase({
                                                                                    ...tempSelectedCase,
                                                                                    FinanceCaseExtension: [{
                                                                                        ...tempSelectedCase?.FinanceCaseExtension?.[0],
                                                                                        InsuranceBonusSource: selectedCaseInsuranceBonusSource
                                                                                    }]
                                                                                });
                                                                            }}
                                                                            onChange={e => {
                                                                                let value: string | undefined = e.target.value;
                                                                                if(value.trim().length == 0){
                                                                                    value = undefined;
                                                                                }
                                                                                setSelectedCaseInsuranceBonusSource(value);
                                                                            }}
                                                                        >
                                                                            <option value="choose" disabled>
                                                                                Velg selskap
                                                                            </option>
                                                                            { insuranceCompaniesFremtind?.map((option: any, index: any) =>
                                                                                <option key={index} value={option.value}>
                                                                                    {option.label}
                                                                                </option>
                                                                            )}
                                                                        </Dropdown>
                                                                    </InputGroup>
                                                                    &nbsp;
                                                                    <InputGroup
                                                                        label="Regnummer på objektet som har bonusen"
                                                                        // tooltip="Registreringsnummer på objektet med bonusen"
                                                                    >
                                                                        {inputProps => (
                                                                            <Input
                                                                                type="text"
                                                                                name="regnumber"
                                                                                value={selectedCaseInsuranceBonusSourceRegnr || ""}
                                                                                onBlur={ () => {
                                                                                    let tempSelectedCase = JSON.parse(JSON.stringify(selectedCase));
                                                                                    setSelectedCase({
                                                                                        ...tempSelectedCase,
                                                                                        FinanceCaseExtension: [{
                                                                                            ...tempSelectedCase?.FinanceCaseExtension?.[0],
                                                                                            InsuranceBonusSourceRegnr: formatRegistrationNumber(selectedCaseInsuranceBonusSourceRegnr || "")
                                                                                        }]
                                                                                    })
                                                                                }}
                                                                                onChange={e => {
                                                                                    let value: string | undefined = e.target.value;
                                                                                    if(value.trim().length == 0){
                                                                                        value = undefined;
                                                                                    }
                                                                                    setSelectedCaseInsuranceBonusSourceRegnr(value);
                                                                                }}
                                                                                {...inputProps}
                                                                            />
                                                                        )}
                                                                    </InputGroup>
                                                                    &nbsp;
                                                                </>
                                                            : null }
                                                            {   selectedCase?.CaseObjects?.[0]?.RegistrationNumber == undefined && (
                                                                    selectedCase?.FinanceCaseExtension?.[0]?.ChassisNumber == undefined ||
                                                                    selectedCase?.FinanceCaseExtension?.[0]?.ChassisNumber == "" ||
                                                                    (selectedCase?.FinanceCaseExtension?.[0]?.ChassisNumber ?? "").length > 9
                                                                ) ?
                                                                    <p style={{color: "red"}}>Du må legge til regnummer eller chassisnummer på det nye objektet for å kunne aktivere forsikring</p>
                                                            : null }
                                                            { selectedCase?.CaseObjects?.[0]?.RegistrationNumber == undefined || selectedCase?.CaseObjects?.[0]?.RegistrationNumber == "" ?
                                                                <InputGroup
                                                                    label="Regnummer"
                                                                    // tooltip="Registreringsnummer på objektet med bonusen"
                                                                >
                                                                    {inputProps => (
                                                                        <Input
                                                                            type="text"
                                                                            name="regnumber"
                                                                            value={selectedCaseRegnr || ""}
                                                                            onChange={e => {
                                                                                let value: string | undefined = e.target.value;
                                                                                if(value.trim().length == 0){
                                                                                    value = undefined;
                                                                                }
                                                                                setSelectedCaseRegnr(value);
                                                                            }}
                                                                            {...inputProps}
                                                                        />
                                                                    )}
                                                                </InputGroup>
                                                            : null}
                                                            { selectedCase?.FinanceCaseExtension?.[0]?.ChassisNumber == undefined || selectedCase?.FinanceCaseExtension?.[0]?.ChassisNumber == "" ?
                                                                <InputGroup
                                                                    label="Chassisnummer"
                                                                    tooltip="Chassisnummer på finansieringsobjektet. Minimum 9 tegn. Kreves for bestilling av forsikring uten regnummer."
                                                                    fieldMessage={
                                                                        selectedCaseChassisNumber != undefined &&
                                                                        selectedCaseChassisNumber != "" &&
                                                                        selectedCaseChassisNumber.length > 9
                                                                            ? "Ugyldig chassisnummer. Må være minimum 9 tegn."
                                                                            : null
                                                                    }
                                                                >
                                                                    {inputProps => (
                                                                        <Input
                                                                            type="text"
                                                                            name="chassisnumber"
                                                                            value={selectedCaseChassisNumber || ""}
                                                                            onChange={e => {
                                                                                let value: string | undefined = e.target.value;
                                                                                if(value.trim().length == 0){
                                                                                    value = undefined;
                                                                                }
                                                                                setSelectedCaseChassisNumber(value);
                                                                            }}
                                                                            {...inputProps}
                                                                        />
                                                                    )}
                                                                </InputGroup>
                                                            : null }
                                                            <hr/>
                                                            <ActionButton
                                                                disabled={
                                                                    activateInsuranceLoading ||
                                                                    (
                                                                        (
                                                                            selectedCase?.CaseObjects?.[0]?.RegistrationNumber == undefined &&
                                                                            (selectedCaseRegnr == undefined || selectedCaseRegnr == "")
                                                                        ) && (
                                                                            (
                                                                                selectedCase?.FinanceCaseExtension?.[0]?.ChassisNumber == undefined ||
                                                                                selectedCase?.FinanceCaseExtension?.[0]?.ChassisNumber == "" ||
                                                                                (selectedCase?.FinanceCaseExtension?.[0]?.ChassisNumber ?? "").length > 9
                                                                            ) && (
                                                                                selectedCaseChassisNumber == undefined ||
                                                                                selectedCaseChassisNumber == ""
                                                                            )
                                                                        )
                                                                    ) ||
                                                                    (
                                                                        Number(selectedCase?.FinanceCaseExtension?.[0]?.InsuranceExistingBonus || 0) > 60 && (
                                                                            selectedCase?.FinanceCaseExtension?.[0]?.InsuranceBonusSource == undefined ||
                                                                            selectedCase?.FinanceCaseExtension?.[0]?.InsuranceBonusSource == ""
                                                                        )
                                                                    ) ||
                                                                    (
                                                                        Number(selectedCase?.FinanceCaseExtension?.[0]?.InsuranceExistingBonus || 0) > 60 && (
                                                                            selectedCaseInsuranceBonusSourceRegnr == undefined ||
                                                                            selectedCaseInsuranceBonusSourceRegnr == ""
                                                                        )
                                                                    )
                                                                }
                                                                onClick={() => {
                                                                    activateInsurance();
                                                                }}
                                                            >Aktiver forsikring</ActionButton>
                                                        </div> 
                                                    </AccordionItem>
                                                </Accordion>
                                            </>
                                        : null }
                                    </>
                                : null }
                            </div>
                        </ContentCard>
                        <ContentCard disabled={selectedCase == null} open={selectedCase != null ? false : undefined} title="Kundeopplysninger">
                            <div className={styles.personalinfo}>
                                <hr/>
                                <p>
                                    <span>Kundenummer</span>
                                    <span>{selectedCase?.CustomerSequence}</span>
                                </p>
                                <hr/>
                                <p>
                                    <span>Navn</span>
                                    <span>{selectedCase?.caseCustomer?.Name || selectedCase?.CustomerName || ""}</span>
                                </p>
                                <hr/>
                                <p>
                                    <span>E-post</span>
                                    <span>{selectedCase?.caseCustomer?.Email || selectedCase?.CustomerEmail}</span>
                                </p>
                                <hr/>
                                <p>
                                    <span>Telefonnummer</span>
                                    <span>{selectedCase?.caseCustomer?.Mobile || selectedCase?.CustomerMobile}</span>
                                </p>
                                <hr/>
                                {selectedCase?.Status != "Utbetalt" ?
                                    selectedCase?.CustomerSector === "8500" ?
                                        <p>
                                            <span>Personnummer</span>
                                            <span>{selectedCase?.CustomerOrganizationNumber}</span>
                                        </p>
                                    : 
                                        <p>
                                            <span>Organisasjonsnummer</span>
                                            <span>{selectedCase?.CustomerOrganizationNumber}</span>
                                        </p>
                                : null}
                                {/* <hr/>
                                <p>
                                    <span>Samtykke for deling (DEMO)</span>
                                    <span>{ selectedCase?.CaseVariables?.find(i => i.PreviewCode == 607) != undefined && selectedCase?.CaseVariables?.find(i => i.PreviewCode == 607)?.AnswerValue != "0" ?
                                        selectedCase?.CaseVariables?.find(i => i.PreviewCode == 607)?.AnswerValue == "6071" ? "Ja" : "Nei"
                                    : "Ikke svart" }</span>
                                </p>
                                <hr/>
                                <p>
                                    <span>Samtykke for e-kommunikasjon (DEMO)</span>
                                    <span>{ selectedCase?.CaseVariables?.find(i => i.PreviewCode == 617) != undefined && selectedCase?.CaseVariables?.find(i => i.PreviewCode == 617)?.AnswerValue != "0" ?
                                        selectedCase?.CaseVariables?.find(i => i.PreviewCode == 617)?.AnswerValue == "6171" ? "Ja" : "Nei"
                                    : "Ikke svart" }</span>
                                </p> */}
                            </div>
                            { selectedCase?.caseCoCustomer?.Name != undefined ?
                                <div className={styles.personalinfo}>
                                    <hr/>
                                    <p style={{ textAlign: "center" }}>
                                        <span style={{ float: "none" }}>Medlåntaker</span>
                                    </p>
                                    <p>
                                        <span>Kundenummer</span>
                                        <span>{selectedCase?.caseCoCustomer?.Sequence}</span>
                                    </p>
                                    <hr/>
                                    <p>
                                        <span>Navn</span>
                                        <span>{selectedCase?.caseCoCustomer?.Name || ""}</span>
                                    </p>
                                    <hr/>
                                    <p>
                                        <span>E-post</span>
                                        <span>{selectedCase?.caseCoCustomer?.Email}</span>
                                    </p>
                                    <hr/>
                                    <p>
                                        <span>Telefonnummer</span>
                                        <span>{selectedCase?.caseCoCustomer?.Mobile}</span>
                                    </p>
                                    <hr/>
                                    {selectedCase?.Status != "Utbetalt" ?
                                        selectedCase?.CustomerSector === "8500" ?
                                            <p>
                                                <span>Personnummer</span>
                                                <span>{selectedCase?.caseCoCustomer?.OrganizationNumber}</span>
                                            </p>
                                        : 
                                            <p>
                                                <span>Organisasjonsnummer</span>
                                                <span>{selectedCase?.caseCoCustomer?.OrganizationNumber}</span>
                                            </p>
                                    : null}
                                </div>
                            : null }
                        </ContentCard>
                        { selectedCase?.FinanceCaseExtension?.[0]?.ObjectSupplierContact != undefined ||
                          selectedCase?.FinanceCaseExtension?.[0]?.ObjectSupplierEmail != undefined ||
                          selectedCase?.FinanceCaseExtension?.[0]?.ObjectSupplierPhone != undefined ?
                            <ContentCard disabled={selectedCase == null} open={selectedCase != null ? false : undefined} title="Selgerinfo">
                                <div className={styles.personalinfo}>

                                    <hr/>
                                    <p>
                                        <span>Navn</span>
                                        <span>{selectedCase?.CaseObjects?.[0]?.SupplierLastName || ""}</span>
                                    </p>
                                    {selectedCase?.FinanceCaseExtension?.[0]?.ObjectSupplierContact != undefined &&
                                        <>
                                            <hr/>
                                            <p>
                                                <span>Kontaktperson - navn</span>
                                                <span>{selectedCase?.FinanceCaseExtension?.[0]?.ObjectSupplierContact || ""}</span>
                                            </p>
                                        </>
                                    }
                                    <hr/>
                                    <p>
                                        <span>Kontaktperson - e-post</span>
                                        <span>{selectedCase?.FinanceCaseExtension?.[0]?.ObjectSupplierEmail || ""}</span>
                                    </p>
                                    <hr/>
                                    <p>
                                        <span>Kontaktperson - telefon</span>
                                        <span>{selectedCase?.FinanceCaseExtension?.[0]?.ObjectSupplierPhone || ""}</span>
                                    </p>
                                </div>
                            </ContentCard>
                        : null }
                        {/* <div className={styles.loadingspinner}>
                            <Spinner large={true} />
                        </div> */}
                    </GridCol>
                </GridRow>
            </Grid>
            <CalculationPrint
                currentLoanapplication={selectedCase}
                calculationResult={selectedCase?.Calculation?.[0]}
                CompanyName={auth.selectedDealer?.CompanyName}
                Name={auth.user?.userdata?.Name || ""}
                Phone={auth.user?.userdata?.Phone || ""}
                triggerPrint={triggerPrint}
                showArea={(area) => general.getCaseAgreement(auth.selectedDealer, selectedCase)?.agreement?.schema?.fields?.includes(area)}
                segment={selectedCase?.FinanceCaseExtension?.[0]?.SchemaCustomerType}
                caseObjectIsNew={selectedCase?.CaseObjects?.[0]?.IsNew}
                type={selectedCase?.Type == undefined ? undefined : (selectedCase.Type == "Loan" ? "Lån" : "Leasing")}
                category={selectedCase?.CaseObjects?.[0]?.ObjectGroup?.toLowerCase()}
                objectMakeName={selectedCase?.CaseObjects?.[0]?.MakeName ?? undefined}
                objectModelName={selectedCase?.CaseObjects?.[0]?.ModelName ?? undefined}
                objectYear={selectedCase?.CaseObjects?.[0]?.ModelYear ?? undefined}
            />
        </div>
    );
}

export default Loanoverview;