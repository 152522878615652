import moment from "moment";
import React, { useEffect, useState } from "react";

import { ReactComponent as SB1foSVG } from "../../../../../Assets/Images/sb1fo.svg";
import nord_norge_pos from '../../../../../Assets/Images/rgb_SB1_Finans_Nord_Norge_verti_pos.png';
import { useAuth } from "../../../../../Auth";
import { useAxios } from "../../../../../AxiosHandler";
import general from "../../../../../functions/general";

import styles from './CalculationPrint.module.scss';
import { config } from "../../../../../functions/config";

function CalculationPrint(props: CalculationPrintProps) {
    const auth = useAuth();
    const axiosHandler = useAxios();
    const axios = axiosHandler.axios;

    const [printTitle, setPrintTitle] = useState<string>("");
    const [printIntro, setPrintIntro] = useState<string>("");
    const [printText, setPrintText] = useState<string>("");
    const [printSignature, setPrintSignature] = useState<string>("");
    const [printFooter, setPrintFooter] = useState<string>("");

    function printTextReplacer(text: string) {
        if (text == undefined || text == null) return "";
        
        let objekt = props.category?.toLowerCase()
        if(props.objectMakeName && props.objectYear){
            objekt = props.objectMakeName + " " + (props.objectModelName ?? "") + " " + (props.objectYear ?? "");
        }

        let finansieringstype = props.type?.toLowerCase();
        if(finansieringstype == "lån"){
            finansieringstype = "lån til kjøp"
        }

        let replacers: any = {
            "[finansieringstype]": finansieringstype,
            "[objekt]": objekt,
            "\n": "<br/>"
        }

        for (let key in replacers) {
            text = text.replaceAll(key, replacers[key]);
        }

        return text;
    }

    async function getPrintTexts() {
        return new Promise((resolve, reject) => {
            let segment = props.segment == "Privat" ? "Private" : "Company";
            axios.single({
                url: "/api/templates/calculation/" + segment,
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
                }
            }).then((res: any) => {
                resolve(res.data);
                setPrintTitle(printTextReplacer(res.data[0]["Title"]));
                setPrintIntro(printTextReplacer(res.data[0]["Introduction"]));
                setPrintText(printTextReplacer(res.data[0]["Text"]));
                setPrintSignature(printTextReplacer(res.data[0]["Signature"]));
                setPrintFooter(printTextReplacer(res.data[0]["Footer"]));
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    useEffect(() => {
        getPrintTexts();
    }, []);

    useEffect(() => {
        if (props.triggerPrint != 0) {
            window.scrollTo({ top: 0});
            getPrintTexts().then((texts) => {
                // Wait for the texts to be loaded by react
                setTimeout(() => {
                    window.print();
                }, 300);
            }).catch((err) => {
                console.log(err);
            });
        }
    }, [props.triggerPrint]);

    return(
        <div className={styles.printapplication + " " + "printonly"}>
            <div className={styles.printheader}>
                <div className={styles.printlogo}>
                    {config.region == "NORD" ? 
                        <img 
                            className={styles.defaultLogo} 
                            src={nord_norge_pos} 
                            alt="SpareBank1 Finans Nord-Norge" 
                            style={{maxWidth: "200px"}}
                        />
                    : config.region == "ØST" ?
                        <SB1foSVG />
                    : null }
                </div>
                <div className={styles.printdate}>
                    <p>Dato: {
                        general.dateToDateString(new Date()) || ""
                    }</p>
                </div>
            </div>
            <p>{props.currentLoanapplication?.customerLastName ? 
                `Kundens navn: ${props.currentLoanapplication?.customerFirstName ? (props.currentLoanapplication?.customerFirstName + " ") : ""}${props.currentLoanapplication.customerLastName}`
            : 
                ""
            }</p>
            <h3 dangerouslySetInnerHTML={{ __html: printTitle }}></h3>
            <p dangerouslySetInnerHTML={{ __html: printIntro }}></p>
            <div className={styles.printcalculationtable}>
                <table>
                    <thead>
                    {!(props.showArea("ingenMvaVedBrukt") && !props.caseObjectIsNew) &&
                        (props.showArea("terminbelopEksMva") || props.showArea("terminbelopFritattMva")) ?
                            <tr>
                                <th>Terminbeløp (eks. mva):</th>
                                <th>
                                    {general.formatPrice(
                                        props?.calculationResult?.TotalPeriodicAmount +
                                        (props.currentLoanapplication?.debtInsuranceRequested ?
                                            ((props.currentLoanapplication?.calculationResult?.TotalPeriodicAmount ?? 0) * (auth?.selectedDealer?.DebtInsurancePercentage ?? 0)
                                        ) : 0), false, true
                                    ) ?? "N/A"}
                                </th>
                            </tr>
                    :
                        <tr>
                            <th>Terminbeløp</th>
                            <th>
                                {general.formatPrice(
                                    (props?.calculationResult?.TotalPeriodicAmount ?? 0) + 
                                    ((props.showArea("ingenMvaVedBrukt") && !props.caseObjectIsNew) ? 0 : props?.calculationResult?.TotalPeriodicAmountVat ?? 0) +
                                    (props.currentLoanapplication?.debtInsuranceRequested ?
                                        ((props.currentLoanapplication?.calculationResult?.TotalPeriodicAmount ?? 0) * (auth?.selectedDealer?.DebtInsurancePercentage ?? 0)
                                    ) : 0), false, true
                                ) ?? "N/A"}
                            </th>
                        </tr>
                    }
                        {/* <tr>
                            <th>{props.showArea("terminbelopEksMva") || props.showArea("terminbelopFritattMva") ? "Terminbeløp (eks. mva):" : "Terminbeløp:"}</th>
                            <th>{props.showArea("terminbelopEksMva") || props.showArea("terminbelopFritattMva") ? 
                                (general.formatPrice(
                                    props?.calculationResult?.TotalPeriodicAmount, false, true
                                ) ?? "N/A") 
                            :  
                                (general.formatPrice(Math.round(
                                    props.calculationResult?.TotalPeriodicAmount + 
                                    props.calculationResult?.TotalPeriodicAmountVat
                                ), true, true) ?? "N/A")
                            }</th>
                        </tr> */}
                    </thead>
                    <tbody>
                        { props.currentLoanapplication?.debtInsuranceRequested || props.currentLoanapplication?.DebtInsurance?.[0]?.PeriodicInsuranceFee ?
                            <tr><td>Herav betalingsforsikring:</td><td>
                                {general.formatPrice(props.currentLoanapplication?.DebtInsurance?.[0]?.PeriodicInsuranceFee ? props.currentLoanapplication?.DebtInsurance?.[0]?.PeriodicInsuranceFee : (props.currentLoanapplication?.calculationResult?.TotalPeriodicAmount ?? 0) * (auth?.selectedDealer?.DebtInsurancePercentage ?? 0), false, true) ?? "N/A"}
                            </td></tr>
                        : null }
                        { props.showArea("kjopesum") || (props.showArea("ingenMvaVedBrukt") && !props.caseObjectIsNew) ?
                            <tr><td>Kjøpesum:</td><td>
                                {(props.showArea("ingenMvaVedBrukt") && !props.caseObjectIsNew) ? 
                                general.formatPrice(props.calculationResult?.CaseObjectPriceExVAT ?? 0, true, true) : 
                                general.formatPrice(props.calculationResult?.CaseObjectPrice ?? 0, true, true)}
                            </td></tr>
                        : null }
                        { props.showArea("ingenMvaVedBrukt") && !props.caseObjectIsNew ? null : 
                            props.showArea("kjopesumInklMva") && props.segment == "Privat" ? 
                                <tr><td>Kjøpesum (inkl. mva):</td><td>
                                    {general.formatPrice(props.calculationResult?.CaseObjectPrice ?? 0, true, true)}
                                </td></tr>
                            : null  
                        }
                        { props.showArea("ingenMvaVedBrukt") && !props.caseObjectIsNew ? null : 
                            props.showArea("kjopesumEksMva") && props.segment != "Privat" ?
                                <tr><td>Kjøpesum (eks. mva):</td><td>
                                    {general.formatPrice(props.calculationResult?.CaseObjectPriceExVAT ?? 0, true, true)}
                                </td></tr>
                            : null 
                        }
                        { props.showArea("kjopesumFritattMva") ?
                            <tr><td>Kjøpesum (fritatt mva):</td><td>
                                {props.calculationResult?.CaseObjectVATExempt ? "Ja" : "Nei"}
                            </td></tr>
                        : null }
                        { props.showArea("startleie") || (props.showArea("ingenMvaVedBrukt") && !props.caseObjectIsNew) ?
                            <tr><td>Startleie:</td><td>
                                {general.formatPrice(props.calculationResult?.DownPayment ?? 0, true, true)}
                            </td></tr>
                        : null }
                        {(props.showArea("ingenMvaVedBrukt") && !props.caseObjectIsNew) ? null : 
                            props.showArea("startleieEksMva") ?
                                <tr><td>Startleie (eks mva):</td><td>
                                    {general.formatPrice(props.calculationResult?.DownPayment ?? 0, true, true)}
                                </td></tr>
                            : null 
                        }
                        {(props.showArea("ingenMvaVedBrukt") && !props.caseObjectIsNew) ? null : 
                            props.showArea("startleieInklMva") ?
                                <tr><td>Startleie (inkl mva):</td><td>
                                    {general.formatPrice((props.calculationResult?.DownPayment + props.calculationResult?.DownPaymentVAT) ?? 0, true, true)}
                                </td></tr>
                            : null
                        }
                        { props.showArea("egenkapital") ?
                            <tr><td>Egenkapital:</td><td>
                                {general.formatPrice(props.calculationResult?.DownPayment ?? 0, true, true)}
                            </td></tr>
                        : null }
                        { props.showArea("nominellRente") && 
                        (props.segment == "Privat" || props.type == "Lån") ? 
                            <tr><td>Nominell rente:</td><td>
                                {general.formatPercentage(props.calculationResult?.BaseInterest + props.calculationResult?.Interest) ?? "N/A"}
                            </td></tr>
                        : null }
                        { props.showArea("effektivRente") ?
                            <tr><td>Effektiv rente:</td><td>
                                {general.formatPercentage(props.calculationResult?.EffectiveInterest) ?? "N/A"}
                            </td></tr>
                        : null }
                        { props.showArea("lopetidManed") ?
                            <tr><td>Løpetid (mnd):</td><td>
                                {props.calculationResult?.Duration} mnd
                            </td></tr>
                        : null }
                        {/* { props.showArea("terminbelop") || props.showArea("terminbelopInklMva") || (props.showArea("ingenMvaVedBrukt") && !props.caseObjectIsNew) ?
                            <tr><td>Terminbeløp</td><td>
                                {general.formatPrice(
                                    (props?.calculationResult?.TotalPeriodicAmount ?? 0) + 
                                    ((props.showArea("ingenMvaVedBrukt") && !props.caseObjectIsNew) ? 0 : props?.calculationResult?.TotalPeriodicAmountVat ?? 0), false, true
                                ) ?? "N/A"}
                            </td></tr>
                        : null }
                        {(props.showArea("ingenMvaVedBrukt") && !props.caseObjectIsNew) ? null :
                            props.showArea("terminbelopEksMva") || props.showArea("terminbelopFritattMva") ?
                                <tr><td>Terminbeløp (eks. mva):</td><td>
                                    {general.formatPrice(
                                        props?.calculationResult?.TotalPeriodicAmount, false, true
                                    ) ?? "N/A"}
                                </td></tr>
                            : null
                        } */}
                        { props.showArea("beregningsgrunnlag") ?
                            <tr><td>Beregningsgrunnlag:</td><td>
                                {general.formatPrice(
                                    (props?.calculationResult?.FinancedAmount ?? 0), false, true
                                ) ?? "N/A"}
                            </td></tr>
                        : null }
                        { props.showArea("termingebyr") ?
                            <tr><td>{"Termingebyr" + (props.showArea("termingebyrEksMva") ? " (eks. mva)" : "") + ":"}</td><td>
                                {general.formatPrice(props.calculationResult?.InvoiceFee ?? 0, true, true)}
                            </td></tr>
                        : null }
                        { props.showArea("tinglysningsgebyr") ?
                            <tr><td>Tinglysingsgebyr:</td><td>
                                {general.formatPrice(props.calculationResult?.DocumentFee ?? 0, true, true)}
                            </td></tr>
                        : null }
                        { props.showArea("etableringskostnad") ?
                            <tr><td>Etableringskostnad:</td><td>
                                {general.formatPrice(props.calculationResult?.AdministrationFee ?? 0, true, true)}
                            </td></tr>
                        : null }
                        { props.calculationResult?.Expenses?.map((expense: any, key: any) => {
                            if (expense.BudgetClassSequence == 5 && !props.showArea("korreksjonMvaPrMndPersonbil")) return undefined;
                            return (
                                <div className={styles.result} key={key}>
                                    <hr/>
                                    <p style={{
                                        paddingRight: "14px"
                                    }}>{general.getBudgetClassName(expense)}</p>
                                    <p>{general.formatPrice(
                                        expense.BudgetClassSequence == 5 ? (expense.Amount * 1.25) : expense.Amount, false, true
                                    ) ?? "N/A"}</p>
                                </div>
                            );
                        }).filter((i: any) => i != undefined) ?? null }
                        {/* <tr><td>Totalt finansieringsbeløp:</td><td>
                            {general.formatPrice(props.calculationResult?.FinancedAmount ?? 0, true, true)}
                        </td></tr> */}
                    </tbody>
                </table>
            </div>
            <p dangerouslySetInnerHTML={{ __html: printText }}></p>
            <div className={styles.printdealerinfo}>
                <p>{props.CompanyName}</p>
                <p>{props.Name}</p>
                <p>Tlf. {props.Phone}</p>
            </div>
            <p dangerouslySetInnerHTML={{ __html: printSignature }}></p>
            <div className={styles.printfooter}>
                <p>
                    { printFooter.split("\\t").map((line: any, index: any) => {
                        return <span key={index} dangerouslySetInnerHTML={{ __html: line }}></span>
                    }) }
                </p>
            </div>
        </div>
    );
}

interface CalculationPrintProps {
    calculationResult: any;
    CompanyName: string;
    Name: string;
    Phone: string;
    currentLoanapplication: any;
    triggerPrint: number;
    showArea: (area: string) => boolean;
    segment: string | undefined;
    caseObjectIsNew: boolean | undefined;
    type: string | undefined;
    category: string | undefined;
    objectMakeName: string | undefined;
    objectModelName: string | undefined;
    objectYear: number | undefined;
}

export default CalculationPrint;