import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { SecondaryButton } from "@sb1/ffe-buttons-react";
import Spinner from "@sb1/ffe-spinner-react";
import { useAuth } from "../../../../Auth";
import { useAxios } from "../../../../AxiosHandler";
import moment from "moment";

import styles from './Notifications.module.scss';

const Notifications = forwardRef((props: NotificationsProps, ref: any) => {
    const axiosHandler = useAxios()
    const axios = axiosHandler.axios;
    const auth = useAuth();
    
    const [loading, setLoading] = useState(false);
    const [archiving, setArchiving] = useState(false);
    const [openItem, setOpenItem] = useState<string>("");
    const [showArchive, setShowArchive] = useState(false);

    const [notifications, setNotifications] = useState<any[]>([]);
    const [archivedNotifications, setArchivedNotifications] = useState<any[]>([]);

    function getHumanTimeSince(date: Date) {
        const now = moment();
        const then = moment(date);
        const diff = now.diff(then, "minutes");
        let answer = "";

        if (diff < 60) {
            answer = diff + " minutter siden";
        } else if (diff < 1440) {
            answer = Math.floor(diff / 60) + " timer siden";
        } else if (diff < 10080) {
            answer = Math.floor(diff / 1440) + " dager siden";
        } else if (diff < 43200) {
            answer = Math.floor(diff / 10080) + " uker siden";
        } else if (diff < 525600) {
            answer = Math.floor(diff / 43200) + " måneder siden";
        } else {
            answer = Math.floor(diff / 525600) + " år siden";
        }
        
        return answer;
    }

    function getNotifications(archived: boolean = false) {
        if (!archived) {
            setLoading(true);
        }
        axios.single({
            url: "/api/notifications/" + (archived ? "archived/" : "") + auth.selectedDealer.Sequence,
            method: "GET",
            headers: {
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
            }
        }).then((res: any) => {
            setLoading(false);
            if (!archived) {
                let data = Array.isArray(res.data) ? res.data : [];
                setNotifications(data);
                props.onLoad?.(data);
            } else {
                setArchivedNotifications(res.data || []);
            }
        }).catch((err: any) => {
            console.log(err);
            setLoading(false);
            //toast.error("Innlasting av brukerdata feilet! Klikk her for å prøve igjen.");
        });
    }

    function archiveNotification(notificationID: string) {
        setArchiving(true);
        axios.single({
            url: "/api/notifications/" + auth.selectedDealer.Sequence,
            data: [
                {
                    NotificationID: notificationID,
                    UserName: auth.user?.userdata?.Username
                }
            ],
            method: "PUT",
            headers: {
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
            }
        }).then((res: any) => {
            setNotifications(notifications.filter((notification) => notification.NotificationID != notificationID));

            getNotifications();
            getNotifications(true);
            setOpenItem("");
            setArchiving(false);
        }).catch((err: any) => {
            console.log(err);
            setArchiving(false);
            //toast.error("Innlasting av brukerdata feilet! Klikk her for å prøve igjen.");
        });
    }

    // Fetch notifications every 5 minutes
    useEffect(() => {
        let interval: any;

        if (auth.selectedDealer?.Sequence != undefined) {
            getNotifications();

            interval = setInterval(() => {
                getNotifications();
            }, 300000);
        }

        return () => clearInterval(interval);
    }, [auth.selectedDealer?.Sequence]);

    useImperativeHandle(ref, () => ({
        archiveNotification(notificationID: string) {
            archiveNotification(notificationID);
        }
    }));

    return (
        <div className={styles.notifications}>
            <div className={styles.new}>
                <h1 className={styles.sectiontitle}>{ notifications.length > 0 ? "Nye meldinger" : "Du har ingen nye meldinger"}</h1>
                { loading ?
                    <div className={styles.loadingcontainer}>
                        <Spinner large={true} />
                    </div>
                : null }
                <ul>
                    { notifications?.map((notification, index) => 
                        <li key={index}>
                            <div className={styles.notification}>
                                <div className={styles.icon} onClick={() => openItem != notification.NotificationID ? setOpenItem(notification.NotificationID) : setOpenItem("")}>
                                    <span className="material-symbols-outlined">lightbulb_circle</span>
                                </div>
                                <div className={styles.content} onClick={() => openItem != notification.NotificationID ? setOpenItem(notification.NotificationID) : setOpenItem("")}>
                                    <h3>{notification.Title}</h3>
                                </div>
                                <div className={styles.time}>
                                    <p>{getHumanTimeSince(notification.Published)}</p>
                                </div>
                            </div>
                            { openItem == notification.NotificationID ? 
                                <div className={styles.notificationtext}>
                                    <p>{notification.Message}</p>
                                    <SecondaryButton
                                        onClick={() => {
                                            if (archiving) return;
                                            archiveNotification(notification.NotificationID);
                                        }}
                                    >Arkivér melding</SecondaryButton>
                                </div>
                            : null }
                        </li>
                    ) }
                </ul>
            </div>
            <div className={styles.archive}>
                { showArchive ?
                    <div className={styles.new}>
                        <h1 className={styles.sectiontitle}>Meldingsarkiv</h1>
                        <ul>
                            { archivedNotifications?.map((notification, index) => 
                                <li key={index}>
                                    <div className={styles.notification}>
                                        <div className={styles.icon} onClick={() => openItem != notification.NotificationID ? setOpenItem(notification.NotificationID) : setOpenItem("")}>
                                            <span className="material-symbols-outlined">lightbulb_circle</span>
                                        </div>
                                        <div className={styles.content} onClick={() => openItem != notification.NotificationID ? setOpenItem(notification.NotificationID) : setOpenItem("")}>
                                            <h3>{notification.Title}</h3>
                                        </div>
                                        <div className={styles.time}>
                                            <p>{getHumanTimeSince(notification.Published)}</p>
                                        </div>
                                    </div>
                                    { openItem == notification.NotificationID ? 
                                        <div className={styles.notificationtext}>
                                            <p>{notification.Message}</p>
                                            <hr/>
                                        </div>
                                    : null }
                                </li>
                            ) }
                        </ul>
                    </div>
                : null }
                <p
                    onClick={() => {
                        if (!showArchive) getNotifications(true);
                        setShowArchive(!showArchive);
                    }}
                >{!showArchive ? "Vis" : "Skjul"} arkiverte meldinger</p>
            </div>
        </div>
    );
});

class NotificationsProps {
    public onLoad?: (messages: any) => void;
}

export default Notifications;